import React from "react";
import I18n from '../../resources/i18n/I18n';
import moment from "moment";
import Datetime from 'react-datetime';

const EventGeneralInfo = ({
    event,
    onDateChange,
    disabled
}) => (
    <div>
        <h5 className="text-title mb-3 mt-5">{I18n.t('ReportGeneralInformationTitle')}
            <sup className="asterisk"> *</sup>
        </h5>
        <p className="input-text-title">{I18n.t('ReportGeneralInformationDateTitle')}
            <sup className="asterisk"> *</sup>
        </p>
        <Datetime
            locale="fr-ca"
            defaultValue={event.date || moment()}
            value={event.date}
            isValidDate={(d) => d.isBefore(new Date())}
            onChange={onDateChange}
            className="date-input-container"
            timeFormat
            inputProps={{ disabled }} />
    </div>
)

export default EventGeneralInfo;
