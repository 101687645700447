import EventGeneralInfo from 'components/event/EventGeneralInfo';
import EventInfoList from 'components/event/EventInfosList';
import EventSubmitButtons from 'components/event/EventSubmitButtons';
import Loader from 'components/loader/Loader';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import I18n from 'resources/i18n/I18n';
import EventApi from 'services/eventApi';
import eventService from 'services/eventService';
import CONSTANTS from "../../utils/constants";
import { errorToast, successToast } from "../../utils/toast";
import Logo from '../logo/Logo';
import EventComplementaryInfos from 'components/event/EventComplementaryInfos';
import EventComment from 'components/event/EventComment';
import EventAddress from 'components/event/EventAddress';
import ORFIDropzone from 'components/files/Dropzone';
import { getEventPatrimonyData } from 'services/event.service';
import RPGDInfo from 'components/files/RPGDInfo';
import ModalAnonymize from 'components/files/ModalAnonymize';

const Report = () => {
  const user = useSelector(state => state.userReducer.user);
  const { id } = useParams();

  const [currentEvent, setCurrentEvent] = useState({
    user,
    group: user.group,
    date: moment(),
    type: null,
    subtype: null,
    category: null,
    locationType: null,
    locationSubtype: null,
    recurrent: false,
    camera: false,
    address: null,
    comment: '',
    building: null,
    floor: null,
    victims: [],
    id,
    idPatrimony: null,
    idSector: null,
    idAgency: null
  });

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(!!id);
  const [redirectPath, setRedirectPath] = useState(null);
  const editMode = id !== undefined;
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const { locations, types } = useSelector(state => state.resourceReducer);
  const [showModalAnonymize, setShowModalAnonymize] = useState(false);
  const [savingEvent, setSavingEvent] = useState(null);
  const [duplicate, setDuplicate] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = async () => {
    let event = { ...currentEvent };
    if (id) {
      await EventApi.getEvent(id).then((response) => {
        eventService.normalizeEvent(response, types, locations);
        setIsFormDisabled(response.currentStatus === CONSTANTS.STATUS_LIST.REFUSED || response.currentStatus === CONSTANTS.STATUS_LIST.VALIDATED)
        setCurrentEvent(response);
        setFiles(response.files.filter((file) => !file.statusId));
        event = response;
      }).catch(() => {
        history.replace('/consulter');
      })
    }

    await fetchPatrimonyData(event);
    setLoading(false);
  };

  const fetchPatrimonyData = async (event) => {
    await getEventPatrimonyData(event, editMode).then(setCurrentEvent);
  };

  const editCurrentEvent = (key, value) => {
    setCurrentEvent({
      ...currentEvent,
      [key]: value
    });
  };

  const selectDate = (date) => {
    //La librairie datepicker ne gère pas la limite sur les heures,
    //on reset la date à la date/heure du jour si l'heure dépasse l'heure actuelle
    editCurrentEvent('date', moment(moment(date).isAfter(moment()) ? moment() : moment(date)));
  };

  const handleEventType = (id) => {
    setCurrentEvent({
      ...currentEvent,
      type: types.find((x) => x.id === id),
      subtype: null,
      category: null
    });
  };

  const handleEventSubType = (id) => {
    setCurrentEvent({
      ...currentEvent,
      subtype: currentEvent.type.subtypes.find((x) => x.id === id),
      category: null
    });
  };

  const handleEventCategory = (id) => {
    const currentCategory = currentEvent.subtype.categories.find((x) => x.id === id);
    setCurrentEvent({
      ...currentEvent,
      category: currentCategory,
      recurrent: !currentCategory.isRecurrentFact ? null : false
    });
  };

  const handleSiteInputChanged = (textSite) => {
    editCurrentEvent('site', textSite.target.value);
  }

  const handleBuildingInputChanged = (textBuilding) => {
    editCurrentEvent('building', textBuilding.target.value);
  }

  const handleFloorInputChanged = (textFloor) => {
    editCurrentEvent('floor', textFloor.target.value);
  }

  const handleEventPlace = (id) => {
    setCurrentEvent({
      ...currentEvent,
      locationType: locations.find((x) => x.id === id),
      locationSubtype: null
    });
  };

  const handleEventSubPlace = (id) => {
    editCurrentEvent('locationSubtype', currentEvent.locationType.subtypes.find((x) => x.id === id));
  };

  const handleEventAddress = (address) => {
    editCurrentEvent('address', address);
  };

  const handlePatrimonyInputChanged = (idPatrimony) => {
    setCurrentEvent({
      ...currentEvent,
      idPatrimony: parseInt(idPatrimony.target.value),
      idSector: null,
      idAgency: null
    });
  };

  const handleSectorInputChanged = (idSector) => {
    setCurrentEvent({
      ...currentEvent,
      idSector: parseInt(idSector.target.value),
      idAgency: null
    });
  };

  const handleAgencyInputChanged = (idAgency) => {
    editCurrentEvent('idAgency', parseInt(idAgency.target.value));
  };

  const handleCommentInputChanged = (textComment) => {
    editCurrentEvent('comment', textComment.target.value);
  };

  const switchRecurrentFact = (state) => {
    editCurrentEvent('recurrent', state);
  };

  const switchCamera = (state) => {
    editCurrentEvent('camera', state);
  };

  const handleVictimChanged = (idVictim) => {
    const oldVictims = currentEvent.victims || [];
    editCurrentEvent('victims', oldVictims.includes(idVictim) ? oldVictims.filter(elem => elem !== idVictim) : [...oldVictims, idVictim]);
  };

  const saveEvent = (anonymize, event = null) => {
    setShowModalAnonymize(false);
    setLoading(true);
    const updatedEvent = event || savingEvent;
    if (editMode) {
      EventApi.updateEvent(updatedEvent).then(async (event) => {
        await EventApi.uploadFiles(updatedEvent.id, files, null, anonymize)
        successToast(I18n.t('EditToastSuccess', { id: event.id }))
        redirectTo({ pathname: `/evenement/${updatedEvent.id}/status` });
      }).catch((e) => {
        errorToast(I18n.t('EditToastError', { id }));
      });
    } else {
      EventApi.createEvent(updatedEvent).then(async (event) => {
        await EventApi.uploadFiles(event.id, files, null, anonymize)
        //Si on ne duplique pas on retourne à l'accueil
        if (!duplicate) {
          successToast(I18n.t('SaveToastSuccess', { id: event.id }))
          redirectTo('/accueil');
        } else {
          successToast(I18n.t('DuplicateToastSuccess', { id: event.id }))
          //Si on duplique :
          // On garde que les infos : date - lieu - adresse - champs patrimoine - espace video surveillance
          const partialEvent = {
            ...updatedEvent,
            type: null,
            subtype: null,
            category: null,
            locationType: null,
            locationSubtype: null,
            recurrent: false,
            comment: null,
            victims: [],
            id: null
          }
          setCurrentEvent(partialEvent);
          window.scrollTo(0, 0)
        }
      }).catch(() => {
        if (duplicate) {
          errorToast(I18n.t('DuplicateToastError'))
        } else {
          errorToast(I18n.t('SaveToastError'))
        }
        setLoading(false);
      }).finally(() => {
        setLoading(false);
        redirectTo('/accueil');
      });
    }
  };

  const onSubmit = (event, duplicateValue = false) => {
    event.preventDefault();
    setDuplicate(duplicateValue);
    let showError = false;

    const updatedEvent ={
      ...currentEvent,
      idGroup: user.group.id
    };

    showError = !eventService.attributesIsNotNull(updatedEvent);
    setShowError(showError);
    if (showError) {
      event.preventDefault();
      return;
    }

    if (files.length) {
      setSavingEvent(updatedEvent);
      setShowModalAnonymize(true);
      return;
    }

    saveEvent(false, updatedEvent);
  };

  const redirectTo = (path) => {
    setRedirectPath(path);
  };

  if (redirectPath) {
    return (<Redirect to={redirectPath} push />)
  }

  return (
    <div className="container-fluid">
      <Logo />
      <div className="container">
        <div className="row">
          <div className="title">
            {`${editMode ? I18n.t('ReportEditTitle') : I18n.t('ReportTitle')} ${currentEvent.id ? currentEvent.id : ''}`}
          </div>
        </div>
        {!loading ?
          <Form onSubmit={onSubmit}>
            <EventGeneralInfo
              event={currentEvent}
              onDateChange={selectDate}
              disabled={isFormDisabled}
            />

            <EventInfoList
              list={types}
              onClick={handleEventType}
              objectSelected={currentEvent.type ? currentEvent.type.id : null}
              itemsContainerClass="row"
              disabled={isFormDisabled}
            />
            <EventInfoList
              list={currentEvent.type ? currentEvent.type.subtypes : []}
              onClick={handleEventSubType}
              objectSelected={currentEvent.subtype ? currentEvent.subtype.id : null}
              disabled={isFormDisabled}
            />
            <EventInfoList
              list={currentEvent.subtype ? currentEvent.subtype.categories : []}
              onClick={handleEventCategory}
              objectSelected={currentEvent.category ? currentEvent.category.id : null}
              customIcon={currentEvent.subtype ? currentEvent.subtype.iconName : null}
              inline
              disabled={isFormDisabled}
            />
            <EventInfoList
              list={currentEvent.category || currentEvent.locationType ? locations : []}
              onClick={handleEventPlace}
              objectSelected={currentEvent.locationType ? currentEvent.locationType.id : null}
              itemsContainerClass="row"
              disabled={isFormDisabled}
            />

            <EventInfoList
              list={currentEvent.locationType ? currentEvent.locationType.subtypes : []}
              onClick={handleEventSubPlace}
              objectSelected={currentEvent.locationSubtype ? currentEvent.locationSubtype.id : null}
              itemsContainerClass="row"
              inline
              disabled={isFormDisabled}
            />

            <EventAddress
              event={currentEvent}
              editMode={editMode}
              onChange={handleEventAddress}
              disabled={isFormDisabled}
            />

            <EventComplementaryInfos
              event={currentEvent}
              onAgencyChange={handleAgencyInputChanged}
              onSectorChange={handleSectorInputChanged}
              onPatrimonyChange={handlePatrimonyInputChanged}
              onBuildingChange={handleBuildingInputChanged}
              handleSiteInputChanged={handleSiteInputChanged}
              handleBuildingInputChanged={handleBuildingInputChanged}
              handleFloorInputChanged={handleFloorInputChanged}
              onRecurrentChange={switchRecurrentFact}
              onVictimChange={handleVictimChanged}
              onCameraChange={switchCamera}
              disabled={isFormDisabled}
              oldDisplay={user.group.oldDisplay}
            />

            <EventComment
              event={currentEvent}
              onChange={handleCommentInputChanged}
              showComment={user.group.enableComment}
              disabled={isFormDisabled}
            />

            {user.group.enableAttachments && (
              <ORFIDropzone
                className='mt-3'
                files={files}
                setFiles={setFiles}
                title={I18n.t('EventFiles')}
                subtitle={<RPGDInfo />}
                downloadFileOnServer={EventApi.downloadFile}
                deleteFileOnServer={EventApi.deleteFile}
                disabled={isFormDisabled}
              />
            )
            }

            <EventSubmitButtons
              event={currentEvent}
              editMode={editMode}
              onSubmit={onSubmit}
              showError={showError}
              disabled={isFormDisabled}
            />
          </Form>
          : <Loader displayMessage />
        }

      </div>
      <ModalAnonymize showModal={showModalAnonymize} setShowModal={setShowModalAnonymize} submit={saveEvent}/>
    </div>
  );
}

export default withRouter(Report);
