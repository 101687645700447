import EventsMap from 'components/eventsMap/EventsMap';
import Home from 'components/home/Home';
import Footer from 'components/nav/Footer';
import Menu from 'components/nav/Menu';
import Report from 'components/report/Report';
import RGPD from 'components/rgpd/RGPD';
import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, withRouter, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KEYCLOAK_STORAGE_ACCESS_TOKEN, KEYCLOAK_URL_BEFORE_LOGIN } from '../constants/keycloakConstants';
import { handleKeycloakCallback } from '../services/keycloakService';
import CategoryEdition from './configuration/CategoryEdition';
import ComplaintEdition from './configuration/ComplaintEdition';
import Configuration from './configuration/Configuration';
import LocationEdition from './configuration/LocationEdition';
import ProcessEdition from './configuration/ProcessEdition';
import EstateEdition from "./configuration/estate/EstateEdition";
import EstateLabelEdition from "./configuration/estate/EstateLabelEdition";
import EstateOrganizationEdition from "./configuration/estate/EstateOrganizationEdition";
import EstateUserEdition from "./configuration/estate/EstateUsersEdition";
import GroupEdition from "./configuration/group/GroupEdition";
import Consult from './consult/Consult';
import Dashboard from './dashboard/Dashboard';
import LoginError from './error/loginError';
import Help from './help/Help';
import Lifecycle from './lifecycle/Lifecycle';
import Loader from './loader/Loader';
import PrivateRoute from './nav/PrivateRoute';
import './nav/menu.scss';
import Stats from './stats/Stats';
import UnAuthenticatedRoute from './nav/UnauthenticatedRoute';
import Login from './login/Login';

const App = () => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search);
    const user = useSelector(state => state.userReducer.user);
    const [loading, setLoading] = useState(params.get('code') && params.get('session_state'));
    const [error, setError] = useState(undefined);
    const history = useHistory();

    useEffect(() => {
        try {

            const accessToken = localStorage.getItem(KEYCLOAK_STORAGE_ACCESS_TOKEN);

            if (accessToken) {
                setLoading(false)
                return
            }

            if (params.get('code') && params.get('session_state') && !user.id) {
                handleKeycloakCallback(params.get('code'), params.get('state'), history)
                    .then(() => {
                        history.replace(localStorage.getItem(KEYCLOAK_URL_BEFORE_LOGIN) || '/accueil');
                        setLoading(false);
                    })
                    .catch(async (err) => {
                        setError(true)
                        try {
                            const error = await err.json()
                            if (error.description) {
                                setError(error.description)
                            }
                        } catch {
                            setError(I18n.t('ErrorLogin'))
                        }
                        setLoading(false)
                    })
                return
            }
        } catch (e) {
            setError(true)
        }
    }, [])

    if (error) {
        return <LoginError error={error} />
    }

    if (loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className="h-100">
            {(user && user.id) ? <Menu /> : null}
            <UnAuthenticatedRoute exact path="/" component={<Login />} />
            <PrivateRoute exact path="/accueil" component={<Home />} />
            <PrivateRoute path="/signalement" component={<Report />} />
            <PrivateRoute path="/signalement/:id" component={<Report />} />
            <PrivateRoute path="/consulter" component={<Consult />} />
            <PrivateRoute path="/statistiques" component={<Stats />} />
            <PrivateRoute path="/statistiques/tableau-de-bord" component={<Dashboard />} />
            <PrivateRoute path="/statistiques/carte" component={<EventsMap />} />
            <PrivateRoute path="/aide" component={<Help />} />
            <PrivateRoute path="/configuration/process" component={<ProcessEdition />} />
            <PrivateRoute path="/configuration/complaint" component={<ComplaintEdition />} />
            <PrivateRoute path="/configuration/category" component={<CategoryEdition />} />
            <PrivateRoute path="/configuration/location" component={<LocationEdition />} />
            <PrivateRoute path="/configuration/group" component={<GroupEdition />} />
            <PrivateRoute path="/organismes/label" component={<EstateLabelEdition />} />
            <PrivateRoute path="/organismes/patrimoine" component={<EstateOrganizationEdition />} />
            <PrivateRoute path="/organismes/utilisateurs" component={<EstateUserEdition />} />
            <PrivateRoute path="/organismes" component={<EstateEdition />} />
            <PrivateRoute path="/configuration" component={<Configuration />} />
            <PrivateRoute path="/evenement/:id/status" component={<Lifecycle />} />
            <Route exact path="/rgpd" component={RGPD} />
            <Footer />
            <ToastContainer />
        </div>
    );
}

export default withRouter(App);
