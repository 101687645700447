import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';

class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.clearSearchBox = this.clearSearchBox.bind(this);
    }

    initSearchBox = () => {
        if (!this.searchBox && this.props.mapApi && this.props.mapApi.places) {
            this.searchBox = new this.props.mapApi.places.Autocomplete(this.searchInput, {
                types: ['address'],
                componentRestrictions: {country: ['fr']}
            });
            this.searchBox.addListener('place_changed', this.onPlacesChanged);
            this.searchBox.bindTo('bounds', this.props.map);
        }
    }

    componentDidMount({ map, mapApi, value } = this.props) {
        this.initSearchBox()
        this.searchInput.value = value;
    }

    componentDidUpdate({ map, mapApi, value } = this.props) {
        this.initSearchBox()
        this.searchInput.value = value;
    }

    componentWillUnmount({ mapApi } = this.props) {
        mapApi.event.clearInstanceListeners(this.searchInput);
    }

    onPlacesChanged = ({ map, addplace } = this.props) => {
        const place = this.searchBox.getPlace();
        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        this.searchInput.blur();
        this.props.updateMarker(place);
    };

    clearSearchBox = () => {
        this.searchInput.value = '';
    };

    render() {
        return (
        <Form.Control
            className="mb-2"
            ref={(ref) => this.searchInput = ref}
            onFocus={this.clearSearchBox}
            placeholder="Saisissez une adresse"
            disabled={this.props.disabled}/>
        );
    }
}

export default SearchBox;