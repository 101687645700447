import React, { Component } from 'react';
import moment from 'moment';
import I18n from '../../resources/i18n/I18n';
import CONSTANTS from '../../utils/constants';
import { Redirect } from "react-router-dom";
import './event.scss';
import { connect } from "react-redux";
import images from 'resources/assets/images';

class Event extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };
    }

    editEvent = () => {
        this.setState({ edit: true });
    };

    exportEvent = (e) => {
        e.stopPropagation();
        this.props.onExport(e);
    };

    render() {
        const { event, isUnread } = this.props;
        const status = CONSTANTS.STATUS.find(x => x.id === event.currentStatus);
        const style = {
            backgroundColor: status.color
        };

        if (this.state.edit) {
            return <Redirect to={{ pathname: `/signalement/${event.id}`, state: {page : this.props.page} }} push />;
        }
        return (
            <div className={`${isUnread ? 'notification-background' : ''} event container`} onClick={this.editEvent}>
                <div className="row h-100">
                    <div className="col-md-1 col-1 d-flex align-items-center justify-content-center">
                        <input id={event.id}
                               key={event.id}
                               checked={this.props.exportList.find(e => e.id.toString() === event.id.toString())}
                               className="filter-checkbox"
                               type="checkbox"
                               value={event.id}
                               onClick={this.exportEvent}/>
                        <label htmlFor={event.id} onClick={(e) => e.stopPropagation()}/>
                    </div>
                    <div className="col-md-2 col-lg-1 col-2 p-0 type-logo d-flex justify-content-center align-items-center">
                        <div className={`icon-${event.type.iconName}`}></div>
                    </div>
                    <div className="col-md-3 offset-md-0 col-11 offset-1 pl-md-3">
                        <div className="subtitle">{I18n.t('EventType')}</div>
                        <div className="event-desc">{event.type.label}</div>
                        <div className="event-desc">{event.subtype.label}</div>
                        <div className="subtitle">{I18n.t('EventId')}</div>
                        <div className="event-desc event-id">{event.id}</div>
                    </div>
                    <div className="col-md-2 offset-md-0 col-11 offset-1">
                        <div className="subtitle">{I18n.t('EventDate')}</div>
                        <span className="event-desc">
                            {moment(event.date).format('DD/MM/YYYY')}
                        </span>
                        <div className="subtitle">{I18n.t('EventAuthor')}</div>
                        <div className="event-desc">{event.user.firstname ? event.user.firstname.charAt(0)+'.'+event.user.lastname : ''}</div>
                    </div>
                    <div className="col-md-2 d-none d-lg-block">
                        {this.props.user.group && !this.props.user.group.oldDisplay ?
                          <>
                              <div className="subtitle">{this.props.group ? this.props.group.labelPatrimony : ''}</div>
                              <span
                                className="event-desc">{event.patrimony && event.patrimony.label ? event.patrimony.label : ''}</span>
                          </> :
                          <>
                              <div className="subtitle">{I18n.t('EventSite')}</div>
                              <span className="event-desc">{event.site}</span>
                          </>
                        }
                    </div>
                    <div className="col-md-2 offset-md-0 col-11 offset-1">
                        <div className="subtitle">{I18n.t('EventAddress')}</div>
                        <span className="event-desc">{event.address.address}</span>
                    </div>
                {(event.files.length > 0 && this.props.user.group.enableAttachments) && <img src={images.pjInfo} className="pj-info"/>}
                </div>
                <div className="status" style={style}>{status.label}</div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
    };
};

export default connect(mapStateToProps)(Event);
