import React from 'react';
import I18n from 'i18n-js';
import EventInputInfo from './EventInputInfo';

const EventPatrimony = ({
    event,
    patrimonies = [],
    disabled,
    handlePatrimonyInputChanged,
    handleSectorInputChanged,
    handleAgencyInputChanged,
    labels = {}
}) => {

    return (
        <div className="row mb-3">
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
                <EventInputInfo
                    title={labels.patrimonyTitle}
                    subtitle={I18n.t('SelectOneOption')}
                    value={event.idPatrimony}
                    onChange={handlePatrimonyInputChanged}
                    choices={patrimonies}
                    disabled={disabled}
                />
            </div>
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
                <EventInputInfo
                    title={labels.sectorTitle}
                    subtitle={I18n.t('SelectOneOption')}
                    value={event.idSector}
                    onChange={handleSectorInputChanged}
                    choices={patrimonies
                        .flatMap(patrimony => patrimony.sectors
                            .filter(sector => sector.idPatrimony && event.idPatrimony && sector.idPatrimony.toString() === event.idPatrimony.toString()))
                        .sort((a, b) => (a.label > b.label) ? 1 : -1)}
                    disabled={disabled}
                />
            </div>
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
                <EventInputInfo
                    title={labels.agencyTitle}
                    subtitle={I18n.t('SelectOneOption')}
                    value={event.idAgency}
                    onChange={handleAgencyInputChanged}
                    choices={patrimonies
                        .flatMap(patrimony => patrimony.sectors
                            .filter(sector => sector.idPatrimony && event.idPatrimony && sector.idPatrimony.toString() === event.idPatrimony.toString())
                            .flatMap(sector => sector.agencies
                                .filter(agency => agency.idSector && event.idSector && agency.idSector.toString() === event.idSector.toString()))
                        ).sort((a, b) => (a.label > b.label) ? 1 : -1)}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default EventPatrimony;