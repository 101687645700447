import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import './filters.scss';


class Autocompletion extends Component {
    constructor(props) {
        super(props);

        /**
         * value: champ de l'input
         * suggestions: liste déroulante à afficher
         * selectedValues: valeurs sélectionnées par l'utilisateur
         */
        this.state = {
            value: '',
            suggestions: [],
            selectedValues: this.props.getInitialValues ? this.props.getInitialValues() : (this.props.initialValues && this.props.initialValues.length > 0) ? this.props.initialValues.map((value) => {
                return { label: value }
            }) : []
        };

        this.mounted = React.createRef();
    }

    renderSuggestion = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    getSuggestionValue = suggestion => suggestion.label;

    /**
     * Récupération des suggestions de la liste déroulante 
     * lorsque l'utilisateur entre une valeur
     */
    getSuggestions = value => {
        if (this.props.suggestions) {
            const inputValue = value ? value.trim().toLowerCase() : 0;
            const inputLength = value ? inputValue.length : 0;

            if (inputLength === 0 && this.props.showOnFocus && this.state.value.length === 0) {
                return this.props.suggestions;
            }

            return inputLength === 0 ? [] : this.props.suggestions.filter(lang =>
                lang.label && lang.label.toLowerCase().includes(inputValue)
            );
        } else {
            return [];
        }
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    /**
     * Quand l'utilisateur clique sur une suggestion de la liste
     */
    onSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            value: '',
            selectedValues: [...this.state.selectedValues, suggestion]
        });
        this.props.onSelect(suggestion);
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        if (this.props.showOnFocus) {
            return;
        }
        this.setState({
            suggestions: []
        });
    };

    removeItem = (index) => {
        const array = [...this.state.selectedValues];
        const item = array[index];
        array.splice(index, 1);
        this.setState({
            selectedValues: array
        });
        this.props.onRemove(item);
    };

    componentDidMount() {
        if (!this.mounted.current) {
            this.mounted.current = true;
        }
        this.onSuggestionsFetchRequested({ value: this.state.value })
    }

    componentWillUnmount() {
        this.mounted.current = false;
    }

    render() {
        const { value, suggestions } = this.state;

        const inputProps = {
            value,
            onChange: this.onChange
        };

        return (
            <div>
                {this.state.selectedValues.map((item, index) => (
                    <div className="chips d-flex justify-content-between align-items-center" key={index}>
                        <span>{item.label}</span>
                        <span className="icon-close" onClick={() => this.removeItem(index)} />
                    </div>
                ))}
                <Autosuggest
                    shouldRenderSuggestions={() => this.props.showOnFocus || this.state.value.length > 0}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                />
            </div>
        )
    }
}

export default Autocompletion;