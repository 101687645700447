export const NotificationActions = {
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION'
};

export function saveNotifications(notifications) {
  return {
    type: NotificationActions.UPDATE_NOTIFICATION,
    notifications
  };
}
