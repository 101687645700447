import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import I18n from 'resources/i18n/I18n';
import Title from '../typo/Title';
import Status from '../status/Status';
import EventApi from '../../services/eventApi';
import ComplaintApi from '../../services/complaintApi';
import ProcessApi from '../../services/processApi';
import StatusButton from '../status/StatusButton';
import FontAwesome from 'react-fontawesome';
import CONSTANTS from '../../utils/constants';
import { fetchProcessSuccess, fetchComplaintSuccess } from 'store/actions/resourceActions';
import CustomButton from 'components/buttons/CustomButton';
import Logo from '../logo/Logo';
import { errorToast, successToast } from 'utils/toast';
import Loader from '../loader/Loader';
import ModalAnonymize from 'components/files/ModalAnonymize';

const Lifecycle = () => {
  const [event, setEvent] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [redirectPath, setRedirectPath] = useState(null);
  const [files, setFiles] = useState([]);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const userAddNewStatut = useRef(false);
  const [showModalAnonymize, setShowModalAnonymize] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const resources = useSelector((state) => state.resourceReducer);

  useEffect(() => {
    setLoading(true);
    EventApi.getEvent(id).then((response) => {
      response.statuses.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEvent(response);
      setIsFormDisabled(response.currentStatus === CONSTANTS.STATUS_LIST.REFUSED || response.currentStatus === CONSTANTS.STATUS_LIST.VALIDATED);
    }).finally(() => setLoading(false));

    if (!resources) {
      return;
    }

    if (resources.processes.length === 0) {
      ProcessApi.getProcessList().then((response) => dispatch(fetchProcessSuccess(response)));
    }

    if (resources.complaints.length === 0) {
      ComplaintApi.getComplaintList().then((response) => dispatch(fetchComplaintSuccess(response)));
    }
  }, [id, resources.processes.length, resources.complaints.length, dispatch]);

  const goBack = () => {
    history.goBack();
  };

  const addStatus = (status) => {
    userAddNewStatut.current = true;
    setNewStatus({ status: status.id });
  };

  const editStatus = (status) => {
    setNewStatus(status);
  };

  const saveStatus = (anonymize) => {
    const redirectPath = {
      pathname: '/consulter'
    };
    
    if (!userAddNewStatut.current) {
      setRedirectPath(redirectPath);
      return;
    }

    setLoading(true);
    
    EventApi.updateStatus(event.id, newStatus).then(async (res) => {
      try {
        if (res.statuses) {
          await EventApi.uploadFiles(event.id, files, res.statuses[res.statuses.length - 1].id, anonymize);
        }
        successToast(I18n.t('EditToastSuccess', { id: event.id }));
      } catch (error) {
        errorToast(I18n.t('EditToastError', { id: event.id }));
        console.error('Error updating status or uploading files:', error);
      } finally {
        setLoading(false);
        setRedirectPath(redirectPath);
      }
    });
  };

  const onSubmitSave = () => {
    if (files.length) {
      setShowModalAnonymize(true);
      return;
    }

    saveStatus(false);
  };
      
  const getFilesForStatus = useCallback((statusId) => {
    return event.files.filter((file) => file.statusId === statusId);
  }, [event]);

  const renderButtons = useCallback(() => {
    if (event.currentStatus === CONSTANTS.STATUS_LIST.VALIDATED || event.currentStatus === CONSTANTS.STATUS_LIST.REFUSED) {
      return null;
    }

    return (
      <div>
        {event.currentStatus !== CONSTANTS.STATUS_LIST.PROCESSING && (
          <StatusButton status={CONSTANTS.STATUS[1]} addStatus={addStatus} />
        )}
        <StatusButton status={CONSTANTS.STATUS[2]} addStatus={addStatus} />
        <StatusButton status={CONSTANTS.STATUS[3]} addStatus={addStatus} />
      </div>
    );
  }, [event, addStatus]);

  if (redirectPath) {
    return <Redirect to={redirectPath} push />;
  }

  return (
    <div className="container-fluid">
      <Logo />
      {!loading ?
      <div className="container">
        <div className="row">
          <Title text={I18n.t('LifecycleTitle')} />
        </div>
        <div className="row">
          {event && event.statuses.map((status) => (
            <Status key={status.id} status={status} files={getFilesForStatus(status.id)} isFormDisabled={isFormDisabled} setFiles={null} readOnly />
          ))}
        </div>
        {event && (
          <div className="row justify-content-center mb-4">
            {renderButtons()}
          </div>
        )}
        {newStatus && (
          <Status
            status={newStatus}
            readOnly={false}
            processes={resources.processes}
            complaints={resources.complaints}
            editStatus={editStatus}
            files={files}
            setFiles={setFiles}
            isFormDisabled={isFormDisabled}
          />
        )}
        <div className="row justify-content-center p-4">
          <div className="p-1">
            <CustomButton onClick={goBack} icon={<FontAwesome name="arrow-left" />} small secondary />
          </div>
          {!isFormDisabled && (
            <div className="p-1">
              <CustomButton onClick={onSubmitSave} title={I18n.t('CommonSave')} small primary />
            </div>
          )}
        </div>
        <ModalAnonymize showModal={showModalAnonymize} setShowModal={setShowModalAnonymize} submit={saveStatus}/>
      </div>
      : <Loader displayMessage />}
    </div>
  );
};

export default Lifecycle;