import { FILE_TYPE_NAME_BY_MIME } from "constants/file.constants";
import { KEYCLOAK_STORAGE_ACCESS_TOKEN, KEYCLOAK_STORAGE_ID_TOKEN, KEYCLOAK_STORAGE_REFRESH_TOKEN } from "../constants/keycloakConstants";
import { errorToast } from "../utils/toast";

const getDefaultHeaders = (headersOverride) => {
  const token = localStorage.getItem(KEYCLOAK_STORAGE_ACCESS_TOKEN);
  
  const headers = {
    'Content-Type': 'application/json',
    ...headersOverride,
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  return Object.fromEntries(Object.entries(headers).filter(([_, value]) => value !== undefined));
};

const redirectToLogin = () => {
  localStorage.clear();
  
  window.location.href = '/';
}

/**
 * Permet de transformer les données d'un objet en format url
 * @param filters
 * @returns {string}
 */
export function filtersToUrlParams(filters) {
  return Object.entries(filters).map((filter) => filter.join('=')).join('&');
}

/**
 * Fonction générique qui envoie les requêtes à l'API
 * @param {*} endpoint 
 * @param {*} method 
 * @param {*} body 
 * @param {*} headers 
 */
const callApi = async (endpoint, method, body, headers) => {
  return fetch(`/api/${endpoint}`, {
    method,
    headers: getDefaultHeaders(headers),
    body
  })
    .then(async (res) => {
      if (res.status === 204) {
        return null;
      }
      const accessToken = localStorage.getItem(KEYCLOAK_STORAGE_ACCESS_TOKEN);

      if(res.status === 401 && endpoint === '/auth/login_callback') {
        return Promise.reject(res);
      }
      
      if (res.status === 401 && accessToken && endpoint !== '/auth/refresh_token') {
        const body = {
          refreshToken: localStorage.getItem(KEYCLOAK_STORAGE_REFRESH_TOKEN)
        }
        try {
          const res = await callApi('/auth/refresh_token', 'POST', JSON.stringify(body))
            if (res.status === 200) {
              return res.json().then((response) => {
                localStorage.setItem(KEYCLOAK_STORAGE_ACCESS_TOKEN, response.accessToken);
                localStorage.setItem(KEYCLOAK_STORAGE_REFRESH_TOKEN, response.refreshToken);
                localStorage.setItem(KEYCLOAK_STORAGE_ID_TOKEN, response.idToken);
                
                return callApi(endpoint, method, body, headers);
              });
            }
            return Promise.reject(res);
        }catch{
          localStorage.clear();
          redirectToLogin();
          return Promise.reject(new Error());
        }
      } else if (res.status === 401) {
        localStorage.clear();
        redirectToLogin();
        return null;
      }

      if(res.status === 201) {
        return Promise.resolve(res);
      }
      
      if (res.status === 403) {
        errorToast('Vous n\'avez pas les droits pour effectuer cette action');
        return Promise.reject(res);
      }
      // si c'est un fichier on retourne directement la réponse
      const contentType = res.headers.get('content-type');
      if(contentType && Object.keys(FILE_TYPE_NAME_BY_MIME).some(disposition => contentType.includes(disposition))) {
        return res;
      }

      return res.json().then((response) => {
        if (!res.ok) {
          return Promise.reject(response);
        }
        return response;
      });
    }).catch((err) => {
      return Promise.reject(err)
    }
    );
}
export default callApi;
