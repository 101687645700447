import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { userReducer } from 'store/reducers/userReducer';
import { resourceReducer } from 'store/reducers/resourceReducer';
import { searchReducer } from 'store/reducers/searchReducer';
import { notificationReducer } from 'store/reducers/notificationReducer';
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage'
import CONSTANTS from "utils/constants";


const persistConfig = {
  key: CONSTANTS.REDUX_PERSIST_KEY,
  storage
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
  userReducer,
  resourceReducer,
  searchReducer,
  notificationReducer
}));

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});
const persistor = persistStore(store);

export { store, persistor };
