import callApi from 'services';

export default {
    getNotificationsCount() {
        return callApi('consults/notifications', 'GET');
    },

    getConsultedEvents() {
        return callApi('consults', 'GET');
    },

    markAsRead(eventsId) {
        return callApi('consults', 'POST', JSON.stringify({
            idEvents: eventsId
        }));
    }
};