import fileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import images from 'resources/assets/images';
import I18n from 'resources/i18n/I18n';
import GroupApi from 'services/groupApi';
import PatrimonyApi from 'services/patrimonyApi';
import { fetchPatrimoniesSuccess } from '../../../store/actions/resourceActions';
import { errorToast, successToast } from '../../../utils/toast';
import CustomButton from '../../buttons/CustomButton';
import Title from '../../typo/Title';
import '../configuration.scss';
import LabelEditionTreePatrimonies from './LabelEditionTreePatrimonies';
import ModalImportCsv from './ModalImportCsv';

const EstateOrganizationEdition = ({ location, history }) => {
  const [error, setError] = useState(null);
  const currentUser = useSelector((state) => state.userReducer.user);
  const resources = useSelector((state) => state.resourceReducer);
  const dispatch = useDispatch();
  const [showModalCsv, setShowModalCsv] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [group, setGroup] = useState(null);
  const [groupId, setGroupId] = useState(null);

  useEffect(() => {
    const groupId = new URLSearchParams(location.search).get('groupId');

    // Si le groupId est donné en paramètre de l'url on refetch les patrimoines associés
    if (groupId) {
      setGroupId(groupId);
      GroupApi.getGroupByCode(groupId)
        .then(setGroup);
      getPatrimonies();
    } else if (currentUser && currentUser.group) {
      GroupApi.getGroupByCode(currentUser.group.id)
        .then((group) => {
          setGroup(group);
          getPatrimonies();
        });
    } else if (resources.patrimonies.length === 0 && !groupId) {
      getPatrimonies();
    }
  }, []);

  /**
     * Permet de récupérer une liste des patrimoines
     */
  const getPatrimonies = (overrideValue) => {
    PatrimonyApi.getPatrimonyList({ groupId, searchValue: overrideValue !== null ? overrideValue : searchValue })
      .then((patrimonies) => {
        dispatch(fetchPatrimoniesSuccess(patrimonies));
      });
  };

  /**
     * Change le state pour affiché la modale d'import par CSV
     */
  const toggleModalImportCsv = () => {
    setShowModalCsv(!showModalCsv);
  };

  /**
     * Lancement de l'importation du CSV fourni
     * @param item
     * @param isDryRun
     */
  const importCsv = (item, isDryRun) => {
    PatrimonyApi.importCsvPatrimonies(item, { groupId }, isDryRun)
      .then((response) => {
        // Récupération des patrimoines mis à jour
        !isDryRun && getPatrimonies();
        if (response) {
          fileDownload(response, 'rapport-erreurs-patrimoines.txt');
          errorToast(I18n.t('ErrorImport'));
        }

        if (!response) {
          successToast(I18n.t(isDryRun ? 'SuccessImportDryRun' : 'SuccessImport'));
        }
      }).catch(() => {
        errorToast(I18n.t('ErrorImport'));
      });
  };

  /**
     * Création d'un patrimoine (call API)
     * @param item
     */
  const createPatrimony = (item) => PatrimonyApi.createPatrimony({ label: item.label, code: item.code, idGroup: groupId || currentUser.group.id })
    .then(() => {
      // Récupération des patrimoines mis à jour
      getPatrimonies();
    }).catch((e) => e);

  /**
     * Modification d'un patrimoine (call API)
     * @param item
     */
  const editPatrimony = (item) => PatrimonyApi.updatePatrimony(item)
    .then(() =>
      // Récupération des patrimoines mis à jour
      getPatrimonies()).catch((e) => e);

  /**
     * Suppression d'un patrimoine (call API)
     * @param item
     */
  const deletePatrimony = (item) => {
    setError(null);
    PatrimonyApi.deletePatrimony(item)
      .then(() =>
        // Récupération des patrimoines mis à jour
        getPatrimonies()).catch(() => setError(I18n.t('ErrorDelete')));
  };

  /**
     * Ajout d'un secteur (call API)
     * @param item
     */
  const createSector = (item) => {
    return PatrimonyApi.createSector({ label: item.label, code: item.code, idPatrimony: item.idParent })
      .then(() =>
        // Récupération des patrimoines mis à jour
        getPatrimonies()).catch((e) => e);
  }

  /**
     * Modification d'un secteur (call API)
     * @param item
     */
  const editSector = (item) => PatrimonyApi.updateSector(item)
    .then(() =>
      // Récupération des patrimoines mis à jour
      getPatrimonies()).catch((e) => e);

  /**
     * Suppression d'un secteur (call API)
     * @param item
     */
  const deleteSector = (item) => {
    setError(null);
    PatrimonyApi.deleteSector(item)
      .then(() =>
        // Récupération des patrimoines mis à jour
        getPatrimonies()).catch(() => setError(I18n.t('ErrorDelete')));
  };

  /**
     * Création d'une agence (call API)
     * @param item
     */
  const createAgency = (item) => PatrimonyApi.createAgency({ label: item.label, code: item.code, idSector: item.idParent })
    .then(() =>
      // Récupération des patrimoines mis à jour
      getPatrimonies()).catch((e) => e);

  /**
     * Modification d'une agence (call API)
     * @param item
     */
  const editAgency = (item) => PatrimonyApi.updateAgency(item)
    .then(() =>
      // Récupération des patrimoines mis à jour
      getPatrimonies()).catch((e) => e);

  /**
     * Supression d'une agence (call API)
     * @param item
     */
  const deleteAgency = (item) => {
    setError(null);
    PatrimonyApi.deleteAgency(item).then(() =>
      // Récupération des patrimoines mis à jour
      getPatrimonies()).catch(() => setError(I18n.t('ErrorDelete')));
  };

  /**
     * Retour sur la page précédente
     */
  const goBack = () => history.goBack();

  const onSearchValueChange = (event) => {
    if (event.target.value.length > 2 || (searchValue && searchValue.length)) {
      setSearchValue(event.target.value);
      getPatrimonies(event.target.value);
    }
  };

  return (
    <div className="container-fluid estate-configuration">
      <div className="row justify-content-center">
        <img className="logo-small" alt="ORFI" src={images.homeLogo} />
      </div>
      <div className="container">
        <div className="row m-0 justify-content-between">
          <Title text={`${I18n.t('EstateConfiguration')}${group && currentUser.roles.indexOf('ORFI-ADMIN') > -1 ? ` : ${group.name || 'Nom inconnu'}` : ''}`} />
        </div>
        <LabelEditionTreePatrimonies
          patrimonies={resources.patrimonies || []}
          createPatrimony={createPatrimony}
          editPatrimony={editPatrimony}
          deletePatrimony={deletePatrimony}
          createSector={createSector}
          editSector={editSector}
          deleteSector={deleteSector}
          createAgency={createAgency}
          editAgency={editAgency}
          deleteAgency={deleteAgency}
          importPatrimony={toggleModalImportCsv}
          error={error}
          group={group}
          onSearchValueChange={(event) => onSearchValueChange(event)}
          searchValue={searchValue}
        />
        <div className="row justify-content-center p-4">
          <CustomButton onClick={goBack} icon={<FontAwesome name="arrow-left" />} small secondary />
        </div>
      </div>
      <ModalImportCsv
        showModal={showModalCsv}
        toggleModal={toggleModalImportCsv}
        importCsv={importCsv}
        type="patrimonies"
      />
    </div>
  );
}

export default withRouter(EstateOrganizationEdition);
