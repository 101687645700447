import { Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import {Redirect} from 'react-router-dom';

/**
 * Composant qui permet de gérer les routes non authentifiées
 * 
 * @param {Object} props Les props
 * @param {Object} props.component Le composant à afficher
 * @param {string} props.path Le chemin de la route
 * @returns {React.Component} Le composant
 */
const UnAuthenticatedRoute = ({ component, path }) => {
    const user = useSelector(state => state.userReducer.user);
    const currentRoute = window.location.pathname;

    if(user && user.id && currentRoute === path) {
        return <Redirect to="/accueil" />
    }

    return currentRoute !== path || user && user.id ? <></>  : (
        <Route
            path={path}
            render={() => component}
        />
    )
}
export default UnAuthenticatedRoute;
