import React from 'react';
import Switch from 'react-switch'
import variables from '../report/report.scss';
import I18n from 'i18n-js';

const EventCamera = ({
    event,
    onChange,
    disabled
}) => (
    <>
        <div className="d-flex">
            <div key={`inline-switch-camera`}>
                <Switch
                    className="mr-3 d-flex"
                    onChange={onChange}
                    checked={event.camera || false}
                    onColor={variables.primary}
                    offColor={variables.secondary}
                    uncheckedIcon={null}
                    checkedIcon={null}
                    height={22}
                    width={40}
                    disabled={disabled}
                />
            </div>
            <span className="input-text-title">{I18n.t('ReportCamera')}</span>
        </div>
        <p className="input-subtext-title">{I18n.t('ReportCameraDescription')}</p>
    </>
)

export default EventCamera;