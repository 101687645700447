import { Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { KEYCLOAK_STORAGE_ACCESS_TOKEN } from 'constants/keycloakConstants';
import {  Redirect } from 'react-router-dom';

/**
 * Composant qui permet de gérer les routes authentifiées
 * 
 * @param {Object} props Les props
 * @param {Object} props.component Le composant à afficher
 * @param {string} props.path Le chemin de la route
 * 
 * @returns {React.Component} Le composant
 */
const PrivateRoute = ({ component, path }) => {
    const user = useSelector(state => state.userReducer.user);
    const currentRoute = window.location.pathname;
    const accessToken = localStorage.getItem(KEYCLOAK_STORAGE_ACCESS_TOKEN);
    const query = new URLSearchParams(window.location.search);

    if((!user || !user.id || !accessToken) && currentRoute === path && (currentRoute !== '/' && !query.code)) {
        return <Redirect to="/" />
    }

    return (
        <Route
            exact path={path}
            render={() => component}
        />
    )
}
export default PrivateRoute;
