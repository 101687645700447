import React from 'react';
import I18n from '../../resources/i18n/I18n';
import variables from '../report/report.scss'
import Switch from 'react-switch'
import EventCamera from './EventCamera';
import EventVictims from './EventVictims';
import EventOldPatrimony from './EventOldPatrimony';
import EventPatrimony from './EventPatrimony';
import { useSelector } from 'react-redux';
const EventComplementaryInfos = ({
    event,
    oldDisplay,
    onRecurrentChange,
    onCameraChange,
    onVictimChange,
    onAgencyChange,
    onPatrimonyChange,
    onSectorChange,
    handleSiteInputChanged,
    handleBuildingInputChanged,
    handleFloorInputChanged,
    disabled
}) => {
    const { patrimonies } = useSelector(state => state.resourceReducer);
    const user = useSelector(state => state.userReducer.user);

    return (
        <div>
            <h5 className={`text-title my-3 ${event.locationSubtype ? "pt-5" : ""}`}>
                {I18n.t('ReportComplementaryInformationTitle')}
            </h5>
            {
                event.category && event.category.isRecurrentFact &&
                <>
                    <div className="d-flex">
                        <div key={`inline-switch-recurrent`}>
                            <Switch
                                className="mr-3 d-flex"
                                onChange={onRecurrentChange}
                                checked={event.recurrent || false}
                                onColor={variables.primary}
                                offColor={variables.secondary}
                                uncheckedIcon={null}
                                checkedIcon={null}
                                height={22}
                                width={40}
                                disabled={disabled}
                            />
                        </div>
                        <span className="input-text-title">{I18n.t('ReportRecurrentFact')}</span>
                    </div>
                    <p className="input-subtext-title">{I18n.t('ReportRecurrentFactDescription')}</p>
                </>
            }

            <EventCamera
                event={event}
                onChange={onCameraChange}
                disabled={disabled}
            />
            <EventVictims
                event={event}
                onChange={onVictimChange}
                disabled={disabled}
            />
            {oldDisplay && (
                <EventOldPatrimony
                    event={event}
                    handleSiteInputChanged={handleSiteInputChanged}
                    handleBuildingInputChanged={handleBuildingInputChanged}
                    handleFloorInputChanged={handleFloorInputChanged}
                    disabled={disabled}
                />)
            }
            {!oldDisplay && (
                <EventPatrimony
                    event={event}
                    patrimonies={patrimonies}
                    handlePatrimonyInputChanged={onPatrimonyChange}
                    handleSectorInputChanged={onSectorChange}
                    handleAgencyInputChanged={onAgencyChange}
                    disabled={disabled}
                    labels={{
                        patrimonyTitle: user.group && user.group.labelPatrimony ? user.group && user.group.labelPatrimony : I18n.t('PatrimonyCriteria'),
                        sectorTitle: user.group && user.group.labelSector ? user.group && user.group.labelSector : I18n.t('SectorCriteria'),
                        agencyTitle: user.group && user.group.labelAgency ? user.group && user.group.labelAgency : I18n.t('AgencyCriteria')
                    }}
                />
            )
            }
        </div>
    )
}

export default EventComplementaryInfos;