export const KEYCLOAK_STATE_KEY = 'oauthState'

export const KEYCLOAK_PARAMS = {
    response_type: 'code',
    scope: 'openid profile email'
}

export const KEYCLOAK_STORAGE_ACCESS_TOKEN = 'KEYCLOAK_STORAGE_ACCESS_TOKEN'

export const KEYCLOAK_STORAGE_REFRESH_TOKEN = 'KEYCLOAK_STORAGE_REFRESH_TOKEN'

export const KEYCLOAK_STORAGE_ID_TOKEN = 'KEYCLOAK_STORAGE_ID_TOKEN'

export const KEYCLOAK_URL_BEFORE_LOGIN = 'KEYCLOAK_URL_BEFORE_LOGIN';