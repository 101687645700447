import I18n from '../resources/i18n/I18n';

export const CONSTANTS = {
    DATE_FORMATS: {
        DDMMYYYY: "DD/MM/YYYY"
      },
      TIME_FORMATS: {
        HHHMM: "HH[h]mm"
      },
    STATUS: [
        {
            id: 'NEW',
            label: I18n.t('StatusNew'),
            color: '#4FCDC4',
            icon: 'add'
        },
        {
            id: 'PROCESSING',
            label: I18n.t('StatusProcessing'),
            color: '#FFC107',
            icon: 'sync'
        },
        {
            id: 'VALIDATED',
            label: I18n.t('StatusValidated'),
            color: '#28A745',
            icon: 'check'
        },
        {
            id: 'REFUSED',
            label: I18n.t('StatusRefused'),
            color: '#DC3545',
            icon: 'ban'
        }
    ],
    STATUS_LIST: {
        NEW: 'NEW',
        PROCESSING: 'PROCESSING',
        VALIDATED: 'VALIDATED',
        REFUSED: 'REFUSED'
    },
    DEFAULT_COORDS: {
        lat: 48.864716,
        lng: 2.349014
    },
    DEFAULT_ZOOM: {
        zoom: 11,
        largeZoom: 10
    },
    COLORS: [
        '#2d75e5', '#f4576f', '#efe93f', '#50c9f9', '#a3e33c', '#ae7442', '#9b37c8', '#65c7a5', '#ff8c00'
    ],
    CSV_HEADERS: [
        { label: "Code", key: "id" },
        { label: "Date", key: "date" },
        { label: "Site", key: "site" },
        { label: "Bâtiment", key: "building" },
        { label: "Etage", key: "floor" },
        { label: "Statut", key: "currentStatus" },
        { label: "Fait récurrent", key: "recurrent" },
        { label: "Espace surveillé", key: "camera" },
        { label: "Patrimoine", key: "patrimony.label" },
        { label: "Secteur", key: "sector.label" },
        { label: "Agence", key: "agency.label" },
        { label: "Déclarant", key: "user.username" },
        { label: "Rôle du déclarant", key: "user.roles.0.label" },
        { label: "Groupe", key: "idGroup" },
        { label: "Nom du Groupe", key: "groupName" },
        { label: "Type", key: "type.label" },
        { label: "Sous-type", key: "subtype.label" },
        { label: "Catégorie", key: "category.label" },
        { label: "Lieu", key: "locationType.label" },
        { label: "Sous-lieu", key: "locationSubtype.label" },
        { label: "Addresse", key: "address.address" },
        { label: "Code postal", key: "address.zipcode" },
        { label: "Ville", key: "address.city" }
    ],
    ROLES: {
        DIRECTOR: 'ORFI-DIRECTEUR',
        ADMIN: 'ORFI-ADMIN',
        MANAGER: 'ORFI-MANAGER',
        GESTIONNAIRE: 'ORFI-GESTIONNAIRE'
    },
    REDUX_PERSIST_KEY: 'orfi-persist'
};

export default CONSTANTS;