import React, { Component } from 'react';

import './iconCard.scss';
import 'resources/fonts/style.scss'
import {connect} from "react-redux";

class IconCard extends Component {
  renderStateOfCard = () => {
    let cardClassNames = "card-container";
    if (this.props.edit) {
      cardClassNames += " edit"
    }
    if (this.props.idSelected) {
      cardClassNames += " selected-card"
    }
    if (this.props.inline) {
      cardClassNames += " inline ml-0"
    }
    if (this.props.disabled) {
      cardClassNames += " noHover"
    }
    return cardClassNames;
  };

  render() {
    const notificationCount = this.props.notifications || 0;

    return (
        <div className={`${this.renderStateOfCard()} position-relative`} onClick={this.props.onClick}>
          {
            this.props.displayNotification &&
            notificationCount > 0 &&
            (this.props.user && this.props.user.roles && ((this.props.user.roles.indexOf('ORFI-MANAGER') > -1) || (this.props.user.roles.indexOf('ORFI-DIRECTEUR') > -1))) &&
            <div className="notification-bell">
              <div className="notification"><
                  span>{notificationCount > 99 ? '99+' : notificationCount}</span>
              </div>
            </div>
          }
          <div className={`${this.props.inline ? "d-flex" : ""}`}>
            <div className={this.props.inline ? "center-item" : "icon-container"}>
              <span className={"icon-card " + this.props.iconType}/>
            </div>
            <div className={this.props.inline ? "inline-title center-item" : "content-card"}>
              {this.props.edit ? null : <p className="title-card">{this.props.title}</p>}
              <span className="description-card">{this.props.description}</span>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    notifications: state.notificationReducer.notifications
  };
};

export default connect(mapStateToProps)(IconCard);