import React, { Component } from 'react';
import { connect } from 'react-redux';
import CONSTANTS from '../../utils/constants';
import I18n from 'resources/i18n/I18n';
import Filters from '../filters/Filters';
import EventApi from '../../services/eventApi';
import ActionBar from '../consult/ActionBar';
import Title from '../typo/Title';
import { Redirect } from "react-router-dom";
import './eventsMap.scss';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { GOOGLE_MAPS_KEY } from 'services/config';
import Logo from '../logo/Logo';

class EventsMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultCoords: CONSTANTS.DEFAULT_COORDS, // Par défaut on set les coordonnées à la ville de Paris
      currentZoom: CONSTANTS.DEFAULT_ZOOM.largeZoom,
      loading: false,
      eventList: [],
      showFilters: false,
      editId: null
    };
  }

  componentDidMount() {
    // Récupération intiale des événements
    if (this.state.eventList.length === 0) {
      this.fetchEvents();
    }
  };

  componentWillReceiveProps(nextProps) {
    //Actualisation des résultats au changement de filtres
    this.fetchEvents(nextProps.filters);
  };

  fetchEvents = (filters) => {
    // Récupération de tous les évnénements qui matchent avec les filtres
    EventApi.getEventList(filters ? filters : this.props.filters, 0, 100, true)
      .then((eventListResponse) => {
        this.setState({ eventList: eventListResponse.records });
      });
  };

  toggleFilters = (show) => { this.setState({ showFilters: show }); };

  goToSelectedEvent = (id) => { this.setState({ editId: id }) };

  generateMarkers = () => {
    return (
      this.state.eventList.map((event, idx) => {
        const status = CONSTANTS.STATUS.find(x => x.id === event.currentStatus)
        return (
          <Marker
            key={idx}
            id={idx} // key pas passé directement au marker
            lat={event.address.latitude}
            lng={event.address.longitude}
            iconType={event.type.iconName}
            color={status.color}
            subType={event.subtype.label}
            category={event.category.label}
            date={event.date}
            onClick={() => this.goToSelectedEvent(event.id)}>
          </Marker>
        )
      }))
  };

  renderMapEvents = () => {
    return (
      <div className="map-display">
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
          defaultCenter={this.state.defaultCoords}
          defaultZoom={this.state.currentZoom}
        >
          {this.generateMarkers()}
        </GoogleMapReact>
      </div>
    )
  };

  renderFilters = () => {
    return (
      <div className="row">
        <div className={"col-md-3 filters " + (!this.state.showFilters ? 'hidden' : '')}>
          <Filters toggleFilters={this.toggleFilters} />
        </div>
        <div className="col-12 col-md-9 mt-3">
          {this.renderMapEvents()}
        </div>
      </div>
    )
  };

  render() {
    if (this.state.editId) {
      return <Redirect to={`/signalement/${this.state.editId}`} push />
    }
    return (
      <div className="container-fluid">
        <div className="container">
          <Logo />
          <div className="row">
            <Title text={I18n.t('MapConsultTitle')} />
            <div className="container d-flex justify-content-end mb-4">
              <ActionBar
                actions={{ export: true }}
                addEvent
                toggleFilters={this.toggleFilters}
              />
            </div>
          </div>
          {this.renderFilters()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.searchReducer
  };
};

export default connect(mapStateToProps)(EventsMap);
