import I18n from 'i18n-js';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const EventVictims = ({
    event,
    onChange,
    disabled
}) => {
    const { victims } = useSelector(state => state.resourceReducer);

    return (
        <div>
            <p className="input-text-title mb-0">{I18n.t('EventVictim')}</p>
            <div className="row no-gutters">
                {
                    victims.map((victim) => (
                        <Form.Check
                            key={victim.id}
                            className="mr-0 col-12"
                            value={event.victims && event.victims.includes(victim.id)}
                            onChange={() => onChange(victim.id)}
                            inline label={victim.label}
                            type="checkbox"
                            id={victim.id}
                            disabled={disabled}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default EventVictims;