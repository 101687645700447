import { saveAs } from 'file-saver';
import I18n from 'i18n-js';
import callApi from 'services';
import eventService from 'services/eventService'
import { errorToast } from 'utils/toast';
export const DEFAULT_SIZE = 10;

function filtersToUrlParams(filters) {
    let url = '';
    if (filters) {
        Object.entries(filters).forEach(([key, value]) => {
            if (value !== undefined) {
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        url += `${key}=${item}&`;
                    });
                } else {
                    url += `${key}=${value}&`;
                }
            }
        });
    }
    return url;
}

/**
 * Ajout de fichiers à un événement
 * @param {number} idEvent
 * @param {File[]} files
    */
const uploadFiles = (idEvent, files, statusId = null, anonymizeFaces = null) => {
    const formData = new FormData();
    files.filter((file) => !file.id).forEach((file) => {
        formData.append('files', file);
    });

    if (!formData.has('files')) {
        return Promise.resolve();
    }

    if(statusId) {
        formData.append('statusId', statusId)
    };

    if (anonymizeFaces) {
        formData.append('anonymizeFaces', true)
    };

    return callApi(`events/${idEvent}/files`, 'POST', formData, {
        'Content-Type': undefined
    });
}

/**
 * Suppression d'un fichier
 * @param {number} idFile
 */
const deleteFile = (idFile) => {
    return callApi(`events/files/${idFile}`, 'DELETE');
}


const base64ToBlob = (base64, type) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
}

/**
 * Téléchargement d'un fichier
 * @param {number} idFile
 * @param {boolean} open si true, ouvre le fichier dans un nouvel onglet et ne le télécharge pas
 */
const downloadFile = (idFile) => {
    return callApi(`events/files/${idFile}/download`, 'GET')
        .then(async ({ filename, content}) => {
            saveAs(base64ToBlob(content, 'application/octet-stream'), filename);
        })
}

export default {
    getEventList(filters, page, size = DEFAULT_SIZE, isMap = false) {
        let url = `events?`;
        if (page) {
            url += `&offset=${page * size}`;
        }
        if (size) {
            url += `&size=${size}`;
        }
        if (isMap) {
            url += `&isMap=true`;
        }

        url += `&${filtersToUrlParams(filters)}`
        return callApi(url, 'GET');
    },
    getEventTypes() {
        return callApi('types', 'GET');
    },
    getLocationTypes() {
        return callApi('locationTypes', 'GET');
    },
    createEvent(event) {
        const eventApi = eventService.generateEventForCreation(event);
        return callApi('events', 'POST', JSON.stringify(eventApi));
    },
    updateEvent(event) {
        const eventApi = eventService.generateEventForCreation(event);
        return callApi(`events/${event.id}`, 'PUT', JSON.stringify(eventApi));
    },
    getEventAggregation(filters, criteria) {
        let groupBy = '';
        if (Array.isArray(criteria)) {
            criteria.forEach(c => groupBy += `groupBy=${c}&`);
        } else {
            groupBy = `groupBy=${criteria}`;
        }
        return callApi(`events/aggregation?${groupBy}&${filtersToUrlParams(filters)}`, 'GET');
    },
    getEventStatistics(filters) {
        return callApi(`events/statistics?${filtersToUrlParams(filters)}`, 'GET');
    },
    getEvent(id) {
        return callApi(`events/${id}`, 'GET');
    },
    deleteEvent(id) {
        return callApi(`events/${id}`, 'DELETE');
    },
    updateStatus(eventId, status) {
        return callApi(`events/${eventId}/status`, 'POST', JSON.stringify(status));
    },
    exportEvents(type, filters) {
        return callApi(`events/export?exportType=${type}&${filtersToUrlParams(filters)}`, 'GET')
            .catch((e) => {
                errorToast(I18n.t('ExportError'));
            });
    },
    uploadFiles,
    deleteFile,
    downloadFile
};