import React, { Component } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import './loader.scss';

class Loader extends Component {
    render() {
        return (
            <div>
                <div className="overlay">
                    <PulseLoader className="spinner" color="white"/>
                    {this.props.displayMessage && <div>Page en cours de chargement</div>}
                </div>
            </div>
        );
    }
}

export default Loader;