export const UserActions = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  SWITCH_TO_DIRECTOR: 'SWITCH_TO_DIRECTOR'
};

export function loginSuccess(loginResponse, isGestionnnaireSwitch) {
  return {
    type: UserActions.LOGIN_SUCCESS,
    loginResponse,
    isGestionnnaireSwitch
  };
}

export function logout() {
  return {
    type: UserActions.LOGOUT
  };
}