import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import { slide as Burger } from 'react-burger-menu';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import images from 'resources/assets/images';
import { redirectToLogin, redirectToLogout } from '../../services/keycloakService';
import NotificationApi from "../../services/notificationApi";
import { saveNotifications } from "../../store/actions/notificationsActions";
import Utils from "../../utils/utils";
import './menu.scss';
import CustomButton from '../buttons/CustomButton';

const Menu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const user = useSelector(state => state.userReducer.user);
    const notifications = useSelector(state => state.notificationReducer.notifications);
    const dispatch = useDispatch();
    const displayNotifications = () => user && user.roles && ((user.roles.indexOf('ORFI-MANAGER') > -1) || (user.roles.indexOf('ORFI-DIRECTEUR') > -1));

    const handleStateChange = ({isOpen}) => {
        setMenuOpen(isOpen);
        if (isOpen && displayNotifications()) {
            NotificationApi.getNotificationsCount().then(notificationsCount =>
                dispatch(saveNotifications(notificationsCount.count))
            );
        }
    };

    const closeMenu = () => setMenuOpen(false);

    const notificationCount = notifications || 0;

    return (
        <Burger
            isOpen={menuOpen}
            onStateChange={handleStateChange}
        >
            <Link onClick={closeMenu} to="/accueil"><Image className="menu-logo" src={images.homeLogo} /></Link>
            <Link onClick={closeMenu} className="menu-item" to={'/signalement'}><span className="icon-warning" />Signaler</Link>
            <Link onClick={closeMenu} className="menu-item" to="/consulter">
                <div className="d-flex align-items-center"><span className="icon-pictoSearch" />Consulter
                    {
                        notificationCount > 0 &&
                        <div className="ml-2 notification">
                            <span>{notificationCount > 99 ? '99+' : notificationCount}</span>
                        </div>
                    }
                </div>
            </Link>
            <Link onClick={closeMenu} className="menu-item" to="/statistiques"><span className="icon-bar-chart" />Statistiques</Link>
            {user && user.roles && user.roles.indexOf('ORFI-ADMIN') > -1 &&
                <Link onClick={closeMenu} className="menu-item" to="/configuration"><span className="icon-settings" />Configuration</Link>
            }
            {user && user.roles && user.roles.indexOf('ORFI-GESTIONNAIRE') > -1 &&
                <Link onClick={closeMenu} className="menu-item" to="/organismes"><span className="icon-settings" />Administration</Link>
            }
            <Link onClick={closeMenu} className="menu-item" to="/aide"><span className="icon-help" />Aide</Link>
            <div className="footer-menu mb-2">
                <div>{`${user.firstname} ${user.lastname}`}</div>
                <div className={'my-1'}>{`${user.email}`}</div>
                <div>{`${user.roles && Utils.getRoleName(user.roles)} - ${user.group.name || user.group.id}`}</div>
            </div>
            {user && user.previousSessionAdmin && (
            <CustomButton onClick={redirectToLogin} title={'Repasser Admin'} small primary />
            )}
            <Link className="menu-item logout" to="/accueil" onClick={redirectToLogout}>Déconnexion</Link>
        </Burger>
    );
}

export default Menu;
