import CONSTANTS from "./constants";

class Utils {
    static getRoleName = (roles) => {
        if (!roles || roles.length === 0) {
            return '';
        }

        const rolesArray = Array.isArray(roles) ? roles : [roles];
        
        if (rolesArray.find((role) => role === CONSTANTS.ROLES.ADMIN)) {
            return 'Administrateur';
        }

        if (rolesArray.find((role) => role === CONSTANTS.ROLES.GESTIONNAIRE)) {
            return 'Gestionnaire';
        }

        if (rolesArray.find((role) => role === CONSTANTS.ROLES.DIRECTOR)) {
            return 'Directeur';
        }

        if (rolesArray.find((role) => role === CONSTANTS.ROLES.MANAGER)) {
            return 'Responsable';
        }

        return 'Gardien';
    }

    static getAllRole = () => {
        return [
            'ORFI-GARDIEN',
            'ORFI-GESTIONNAIRE',
            'ORFI-MANAGER',
            'ORFI-DIRECTEUR',
        ]
    };
}

export default Utils;
