import React from 'react';
import { withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import GroupEditionTable from './GroupEditionTable';
import CustomButton from '../../buttons/CustomButton';
import './GroupEdition.scss'

const GroupEdition = ({ history }) => (
    <div className="container-fluid group-edition">
        <GroupEditionTable />
        <div className="row justify-content-center p-4">
            <CustomButton onClick={history.goBack} icon={<FontAwesome name="arrow-left" />} small secondary />
        </div>
    </div>
)
export default withRouter(GroupEdition);
