import React from 'react';
import { Form } from 'react-bootstrap';

const EventOldInputInfos = ({ 
    title,
    subtitle,
    value = '',
    onChange,
    disabled
 }) => (
    <div>
        <p className="input-text-title mt-3 mb-0">{title}</p>
        <Form.Control
          value={value}
          placeholder={subtitle}
          onChange={onChange}
          disabled={disabled} />
      </div>
)

export default EventOldInputInfos;