import React from "react";
import I18n from '../../resources/i18n/I18n';
import Form from 'react-bootstrap/Form';

const EventComment = ({
    event,
    showComment,
    onChange,
    disabled
}) => {

    if (!showComment) {
        return <></>;
    }


    return (
        <div>
            <span className="input-text-title mb-3">{I18n.t('ReportInputComment')}</span>
            <p className="input-subtext-title mb-3">{I18n.t('ReportInputCommentDescription')}</p>
            <Form.Control
                as="textarea" rows={3} maxLength="2047"
                value={event.comment || ''}
                placeholder={I18n.t('ReportInputCommentPlaceholder')}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    )
}

export default EventComment;