import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import './footer.scss';

class Footer extends Component {
    render() {
        return (
            <div className={"footer " + (!['/accueil', '/'].find((path) => path === this.props.location.pathname)  ? "" : "big")}></div>
        );
    }
}

export default withRouter(Footer);