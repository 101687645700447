import {toast} from "react-toastify";

/**
 * Options par défaut du toast
 * @returns ToastPosition
 */
const defaultToast = () => {
  return {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }
}

/**
 * Affiche un toast de succès avec le texte passé en paramètre
 * @param text
 */
export const successToast = (text) => {
  toast.success(text, {
    ...defaultToast()
  });
}

/**
 * Affiche un toast d'erreur avec le texte passé en paramètre
 * @param text
 */
export const errorToast = (text) => {
  toast.error(text, {
    ...defaultToast()
  });
}

/**
 * Affiche un toast de warning avec le texte passé en paramètre
 * @param text
 */
export const warningToast = (text) => {
 toast.warning(text, {
  ...defaultToast()
 });
}

export default {errorToast, successToast};
