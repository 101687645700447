import { SearchActions } from '../actions/searchActions';
import moment from 'moment';
import 'moment/locale/fr';

const initialState = {
    sortBy: 'date.desc',
    patrimony: [],
    site: [],
    idSubtype: [],
    idGroup: [],
    startDate: moment(new Date()).add(-1, 'months'),
    endDate: moment(new Date()),
    status: [],
    city: [],
    author: []
};

/**
 * Reducer pour gérer les filtres sur les événements
 * @param {*} state
 * @param {*} action
 */
export function searchReducer(state = initialState, action) {
    switch (action.type) {
        case SearchActions.ADD_SITE_FILTER:
            return {
                ...state,
                site: [...state.site, action.site]
            };

        case SearchActions.REMOVE_SITE_FILTER:
            return {
                ...state,
                site: state.site.filter(site => site !== action.site)
            };
        case SearchActions.ADD_CITY_FILTER:
            return {
                ...state,
                city: [...state.city, action.city]
            };

        case SearchActions.REMOVE_CITY_FILTER:
            return {
                ...state,
                city: state.city.filter(city => city !== action.city)
            };

        case SearchActions.ADD_AUTHOR_FILTER:
            return {
                ...state,
                author: [...state.author, action.author]
            };

        case SearchActions.REMOVE_AUTHOR_FILTER:
            return {
                ...state,
                author: state.author.filter(author => author !== action.author)
            };

        case SearchActions.ADD_GROUP_FILTER:
            return { 
                ...state, 
                idGroup: [...state.idGroup, action.group] 
            };

        case SearchActions.REMOVE_GROUP_FILTER:
            return { 
                ...state, 
                idGroup: state.idGroup.filter(group => group !== action.group) 
            };

        case SearchActions.ADD_STATUS_FILTER:
            return { 
                ...state, 
                status: [...state.status, action.status] 
            };

        case SearchActions.REMOVE_STATUS_FILTER:
            return { 
                ...state, 
                status: state.status.filter(status => status !== action.status) 
            };

        case SearchActions.UPDATE_SUBTYPE_FILTER:
            return {
                ...state,
                idSubtype: action.subtypes
            };

        case SearchActions.UPDATE_PATRIMONY_FILTER:
            return {
                ...state,
                idAgency: action.patrimony
            };

        case SearchActions.UPDATE_STARTDATE_FILTER:
            return {
                ...state,
                startDate: action.date
            };

        case SearchActions.UPDATE_ENDDATE_FILTER:
            return {
                ...state,
                endDate: action.date
            };
        case SearchActions.RESET_FILTERS:
            return initialState
        default:
            return state;
    }
}

