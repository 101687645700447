import callApi from 'services';
import { filtersToUrlParams } from "./index";
import { store } from '../store/configureStore';
import { loginSuccess } from '../store/actions/userActions';
import { errorToast } from '../utils/toast';
import { KEYCLOAK_STORAGE_ACCESS_TOKEN } from 'constants/keycloakConstants';

const switchToGestionnaire = (groupId) => {
    return callApi('/users/switch', 'POST', JSON.stringify({ groupId }))
        .then((res) => {
            window.dispatchEvent(new Event('storage'));
            store.dispatch(loginSuccess(res, true));
            // dispatch localstorage change evet 
            window.location.href = '/?gestionnaireChange=1';
        })
        .catch(() => {
            errorToast('Une erreur est survenue');
        });
}
    
const getUsersList = (params) => {
    if (params) {
        params = `?${filtersToUrlParams(params)}`
    } else {
        params = ''
    }
    return callApi(`users${params}`, 'GET');
}

const syncUserListWithLdap = (params) => {
    return callApi(`users?${params ? filtersToUrlParams(params) : ''}`, 'PUT');
}

const updateUser = (user) => {
    return callApi(`users/${user.id}`, 'PUT', JSON.stringify(user))
}

const importCsvUserRelations = (csv, params, isDryRun) => {
    let url = isDryRun ? 'dryRun/' : '';
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem(KEYCLOAK_STORAGE_ACCESS_TOKEN)}`);
    const formData = new FormData();
    formData.append('file', csv)
    return callApi(`${url}users/files?${params ? filtersToUrlParams(params) : ''}`, 'POST', formData, myHeaders);
}

const getUserAgencyRelationsExport = (params) => {
    return callApi(`users/agencies?${params ? filtersToUrlParams(params) : ''}`, 'GET')
}

export default {
    getUsersList,
    syncUserListWithLdap,
    updateUser,
    importCsvUserRelations,
    getUserAgencyRelationsExport,
    switchToGestionnaire
}
