import React from 'react';
import './circularProgressIndicator.scss';

/**
 * @class CircularProgressIndicator
 */
class CircularProgressIndicator extends React.Component {

    render() {
        return (
            <div className="circular-progress" style={{
                width: this.props.size,
                height: this.props.size
            }}>
                <div className="inner" style={{
                    width: this.props.size - 4,
                    height: this.props.size - 4
                }}></div>
                <div className="outer"></div>
            </div>
        );
    }
}
export default CircularProgressIndicator;