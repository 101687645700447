import callApi from 'services';

export default {
    getTypeList() {
        return callApi('types', 'GET');
    },
    addCategory(idSubtype, complaint) {
        return callApi(`subtypes/${idSubtype}/categories`, 'POST', JSON.stringify(complaint));
    },
    editCategory(complaint) {
        return callApi(`categories/${complaint.id}`, 'PUT', JSON.stringify(complaint));
    },
    deleteCategory(id) {
        return callApi(`categories/${id}`, 'DELETE');
    }
};