import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import CircularProgressIndicator from '../progress/CircularProgressIndicator';
import './filters.scss';


class Criteria extends Component {
    constructor(props) {
        super(props);

        this.state = {
            heightCriteria: this.props.height || 0,
            isLoading: false
        };
    }

    toggle = () => {
        const { heightCriteria } = this.state;

        if (this.props.onToggle && heightCriteria === 0 && !this.state.isLoading) {
            this.setState((prevState) => ({
                ...prevState,
                isLoading: true
            }));

            const promise = Promise.resolve(this.props.onToggle());

            promise
                .finally(() => {
                    this.setState((prevState) => ({
                        ...prevState,
                        isLoading: false
                    }));
                })
        }

        this.setState({
            heightCriteria: heightCriteria === 0 ? 'auto' : 0
        });
    };

    componentDidMount() {
        if (this.props.defaultToggle) {
            this.toggle();
        }
    }

    render() {
        return (
            <div className='w-100'>
                <div className='d-flex justify-content-between align-items-center header-criteria'
                    onClick={this.toggle}>
                    <span>{this.props.title}</span>
                    <span className={this.state.heightCriteria === 0 ? 'icon-more' : 'icon-less'} />
                </div>
                <AnimateHeight
                    className={this.state.isLoading ? 'd-flex justify-content-center align-items-center w-100' : ''}
                    duration={200}
                    height={this.state.heightCriteria}>
                    {this.state.isLoading ? <CircularProgressIndicator size={30} /> : this.props.children}
                </AnimateHeight>
            </div>
        )
    }
}

export default Criteria;