/** Service pour manipuler un Event */
export default {
  /**
   *  Permet de vérifier que tous les champs obligatoires ont été remplis
   * @param {*} event
   */
  attributesIsNotNull(event) {
    if (!event.type || !event.subtype || !event.date || event.date === 'Invalid date' || !event.idGroup
      || !event.category || !event.locationType || !event.locationSubtype || !event.address) {
      return false;
    }
    return true;
  },

  /**
   * Permet de générer un bean pour la création d'un event sur l'api
   * On remplace les sous entité par leur id
   * @param {*} event
   */
  generateEventForCreation(event) {
    return {
      date: event.date,
      site: event.site,
      building: event.building,
      floor: event.floor,
      recurrent: event.recurrent,
      camera: event.camera,
      idPatrimony: event.idPatrimony,
      idSector: event.idSector,
      idAgency: event.idAgency,
      idType: event.type.id,
      idSubtype: event.subtype.id,
      idCategory: event.category.id,
      idLocationType: event.locationType.id,
      idLocationSubtype: event.locationSubtype.id,
      address: event.address,
      comment: event.comment || null,
      victims: event.victims,
      idGroup: event.idGroup
    };
  },

  /**
   * Permet de générer un objet adresse de l'event en cours de création pour l'api
   * @param {*} place
   * @param lat
   * @param lng
   */
  generateAddressForCreation(place, lat, lng) {
    // Vérifie si l'API Map a trouvé une addresse
    if (place && place.address_components) {
      const number = place.address_components.find(item => item.types.includes('street_number'));
      const road = place.address_components.find(item => item.types.includes('route'));
      const street = `${number ? number.long_name : ''} ${road ? road.long_name : ''}`;

      const zipCode = place.address_components.find(item => item.types.includes('postal_code'));
      const city = place.address_components.find(item => item.types.includes('locality'));

      return {
        address: street,
        longitude: lng,
        latitude: lat,
        city: city ? city.long_name : '',
        zipcode: zipCode ? zipCode.long_name : ''
      }
    }
    return null;
  },

  normalizeEvent(event, types, locationTypes) {
    this.normalizeType(event.type, types);
    this.normalizeSubtype(event.subtype, event.type.subtypes);
    this.normalizeLocationType(event.locationType, locationTypes);
    event.victims = event.victims.map(elem => elem.id)
    event.date = new Date(event.date);
  },

  normalizeType(type, types) {
    if (!type || !types) {
      return;
    }
    const tmpType = types.find((localType) => localType.id === type.id);
    if (tmpType !== undefined) {
      type.subtypes = tmpType.subtypes;
    }
  },

  normalizeSubtype(subtype, subtypes) {
    if (!subtype || !subtypes) {
      return;
    }
    const tmpSubtype = subtypes.find((localSubtype) => localSubtype.id === subtype.id);
    if (tmpSubtype !== undefined) {
      subtype.categories = tmpSubtype.categories;
    }
  },

  normalizeLocationType(locationType, locationTypes) {
    if (!locationType || !locationTypes) {
      return;
    }
    const tmplocationType = locationTypes.find((localLocationType) => localLocationType.id === locationType.id);
    if (tmplocationType !== undefined) {
      locationType.subtypes = tmplocationType.subtypes;
    }
  }
};
