import React from 'react';
import I18n from '../../resources/i18n/I18n';
import EventCreationMap from 'components/eventCreationMap/EventCreationMap';

const EventAddress = ({
    event,
    editMode,
    onChange,
    disabled
}) => {
    if (!event.locationSubtype) {
        return <></>;
    }

    return (
        <div className="map-container">
            <h5 className="text-title mb-3 mt-5">{I18n.t('ReportLocationTitle')}
                <sup className="asterisk"> *</sup>
            </h5>
            <EventCreationMap
                edit={editMode}
                value={event.address}
                onChange={onChange}
                disabled={disabled} />
        </div>
    )
}

export default EventAddress;