export const ResourceActions = {
    FETCH_TYPES_SUCCESS: 'FETCH_TYPES_SUCCESS',
    FETCH_PROCESSES_SUCCESS: 'FETCH_PROCESSES_SUCCESS',
    EDIT_PROCESSES_SUCCESS: 'EDIT_PROCESSES_SUCCESS',
    ADD_PROCESSES_SUCCESS: 'ADD_PROCESSES_SUCCESS',
    DELETE_PROCESSES_SUCCESS: 'DELETE_PROCESSES_SUCCESS',
    FETCH_COMPLAINTS_SUCCESS: 'FETCH_COMPLAINTS_SUCCESS',
    EDIT_COMPLAINTS_SUCCESS: 'EDIT_COMPLAINTS_SUCCESS',
    ADD_COMPLAINTS_SUCCESS: 'ADD_COMPLAINTS_SUCCESS',
    DELETE_COMPLAINTS_SUCCESS: 'DELETE_COMPLAINTS_SUCCESS',
    FETCH_LOCATIONS_SUCCESS: 'FETCH_LOCATIONS_SUCCESS',
    FETCH_VICTIMS_SUCCESS: 'FETCH_VICTIMS_SUCCESS',
    FETCH_PATRIMONIES_SUCCESS: 'FETCH_PATRIMONIES_SUCCESS',
    FETCH_SITES_SUCCESS: 'FETCH_SITES_SUCCESS'
};

export function fetchSiteSuccess(sites) {
    return {
        type: ResourceActions.FETCH_SITES_SUCCESS,
        sites
    };
}

export function fetchTypesSuccess(types) {
    return {
        type: ResourceActions.FETCH_TYPES_SUCCESS,
        types
    };
}

export function fetchProcessSuccess(processes) {
    return {
        type: ResourceActions.FETCH_PROCESSES_SUCCESS,
        processes
    };
}

export function editProcessSuccess(process) {
    return {
        type: ResourceActions.EDIT_PROCESSES_SUCCESS,
        process
    };
}

export function deleteProcessSuccess(process) {
    return {
        type: ResourceActions.DELETE_PROCESSES_SUCCESS,
        process
    };
}

export function addProcessSuccess(process) {
    return {
        type: ResourceActions.ADD_PROCESSES_SUCCESS,
        process
    };
}

export function fetchComplaintSuccess(complaints) {
    return {
        type: ResourceActions.FETCH_COMPLAINTS_SUCCESS,
        complaints
    };
}

export function editComplaintSuccess(complaint) {
    return {
        type: ResourceActions.EDIT_COMPLAINTS_SUCCESS,
        complaint
    };
}

export function deleteComplaintSuccess(complaint) {
    return {
        type: ResourceActions.DELETE_COMPLAINTS_SUCCESS,
        complaint
    };
}

export function addComplaintSuccess(complaint) {
    return {
        type: ResourceActions.ADD_COMPLAINTS_SUCCESS,
        complaint
    };
}

export function fetchLocationsSuccess(locations) {
    return {
        type: ResourceActions.FETCH_LOCATIONS_SUCCESS,
        locations
    };
}

export function fetchVictimSuccess(victims) {
    return {
        type: ResourceActions.FETCH_VICTIMS_SUCCESS,
        victims
    };
}

export function fetchPatrimoniesSuccess(patrimonies) {
    return {
        type: ResourceActions.FETCH_PATRIMONIES_SUCCESS,
        patrimonies
    };
}