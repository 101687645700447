import React, { Component } from "react";
import { PulseLoader } from "react-spinners";

/**
 * Permet d'afficher un loader sur un composant + un overlay
 * WrapperLoader component
 * 
 */
const WrapperLoader = ({
    loading,
    className,
    containerClassName,
    children,
    loadingElementsSize
}) => (
    <div className={`position-relative ${containerClassName}`}>
        {loading && (
            <div className={`overlay-wrapper ${className}`}>
                <PulseLoader className="spinner position-absolute" color="white" size={loadingElementsSize} />
            </div>
        )}
        {children}
    </div>
);

export default WrapperLoader;