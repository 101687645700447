import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from "moment";

class Marker extends Component {
    render() {
      return (
          <div className="marker-container" onClick={this.props.onClick} data-tip data-for={`"${this.props.id}"`} data-multiline>
            <ReactTooltip type="light" effect="solid" id={`"${this.props.id}"`}>
                Sous-type : {this.props.subType} <br/>
                Catégorie : {this.props.category}<br/>
                Date : {moment(this.props.date).format('DD/MM/YYYY')}
            </ReactTooltip>
            <span className={"icon-card-container icon-card icon-" + this.props.iconType} style={{ backgroundColor: this.props.color }}/>
            <span className={"icon-location pin-map"} style={{ color : this.props.color }}/>
          </div>
      );
    }
}

export default Marker;
