import callApi from 'services';
import {filtersToUrlParams} from "./index";
import { KEYCLOAK_STORAGE_ACCESS_TOKEN } from 'constants/keycloakConstants';
import I18n from 'i18n-js';
import { saveAs } from 'file-saver';
import { errorToast } from 'utils/toast';
/* global FormData */

export default {
    getPatrimonyList(params) {
        return callApi(`patrimonies?${params ? filtersToUrlParams(params) : ''}`, 'GET');
    },
    importCsvPatrimonies(csv,params, isDryRun) {
        let url = isDryRun ? 'dryRun/' : '';
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem(KEYCLOAK_STORAGE_ACCESS_TOKEN)}`);
        const formData = new FormData();
        formData.append('file', csv)
        return callApi(`${url}patrimonies/files?${params ? filtersToUrlParams(params) : ''}`, 'POST', formData, myHeaders);
    },
    createPatrimony(patrimony) {
        return callApi(`patrimonies`, 'POST', JSON.stringify(patrimony));
    },
    updatePatrimony(patrimony) {
        return callApi(`patrimonies/${patrimony.id}`, 'PUT', JSON.stringify(patrimony));
    },
    deletePatrimony(patrimony) {
        return callApi(`patrimonies/${patrimony.id}`, 'DELETE');
    },
    createSector(sector) {
        return callApi(`sectors`, 'POST', JSON.stringify(sector));
    },
    updateSector(sector) {
        return callApi(`sectors/${sector.id}`, 'PUT', JSON.stringify(sector));
    },
    deleteSector(sector) {
        return callApi(`sectors/${sector.id}`, 'DELETE');
    },
    createAgency(agency) {
        return callApi(`agencies`, 'POST', JSON.stringify(agency));
    },
    updateAgency(agency) {
        return callApi(`agencies/${agency.id}`, 'PUT', JSON.stringify(agency));
    },
    deleteAgency(agency) {
        return callApi(`agencies/${agency.id}`, 'DELETE');
    },
    exportPatrimony() {
        return callApi(`patrimonies/export`, 'GET')
        .then(async (response) => {
            saveAs(await response.blob(), response.headers.get('Content-Disposition').split('filename=')[1]);
        })
        .catch(() => {
            errorToast(I18n.t('ErrorExport'));
        });
        
    }
};
