export const FILE_TYPES_SUPPORTED = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword', 'application/msexcel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

export const FILE_TYPE_NAME_BY_MIME = {
    'image/png': '.png',
    'image/jpeg': '.jpeg',
    'image/jpg': '.jpg',
    'application/pdf': '.pdf',
    'application/msword': '.doc',
    'application/msexcel': '.xls',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'text/plain': '.txt',
    'text/csv; charset=UTF-8': '.csv',
};