import I18n from "../../resources/i18n/I18n";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import FontAwesome from "react-fontawesome";
import { errorToast, successToast } from "utils/toast";
import variables from '../report/report.scss';
import './Dropzone.scss';
import WrapperLoader from "components/loader/WrapperLoader";
import { FILE_TYPE_NAME_BY_MIME } from "constants/file.constants";
import Modal from 'react-bootstrap/Modal';
import CustomButton from 'components/buttons/CustomButton';

const focusedStyle = {
    borderColor: variables.primary
};

const acceptStyle = {
    border: '2px solid',
    borderColor: variables.primary
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const ORFIDropzone = ({
    title,
    subtitle,
    mimeTypes = ['image/png', 'image/jpeg', 'image/jpg'],
    className = '',
    disabled,
    files = [],
    downloadFileOnServer,
    deleteFileOnServer,
    setFiles,
    readOnly,
    maxFiles = 2,
}) => {
    const [loadingFiles, setLoadingFiles] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showModal, setShowModal] = React.useState(false);
    const [filesToDelete, setFilesToDelete] = React.useState(null);

    const toggleRemoveModal = (file) => {
        if (file) {
            setFilesToDelete(file); 
        }
        setShowModal(!showModal);
    };

    const onDrop = useCallback((acceptedFiles) => {
        for (const file of acceptedFiles) {
            const errorMessage = validator(file, acceptedFiles.length);
            if (errorMessage) {
                errorToast(`${file.name} : ${errorMessage}`);
                return;
            }
            setFiles([...files, ...acceptedFiles]);
        }
    });

    const validator = (file, nbFiles) => {
        if (!mimeTypes.includes(file.type)) {
            return I18n.t('DropzoneMimeTypeInvalid');
        }

        if (file.size > 1024 * 1024 * 10) {
            return I18n.t('DropzoneFileMaxSizeError');
        }

        if (files.length >= maxFiles || files.length + nbFiles > maxFiles) {
            setErrorMessage(`${I18n.t('DropzoneMaxFilesErrorMessage')} ${maxFiles}`);
            return I18n.t('DropzoneMaxFilesErrorToast');
        }
        
        setErrorMessage('');
        return null;
    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({ onDrop, disabled, readOnly }, validator);

    const style = useMemo(() => ({
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const downloadFile = (file) => {
        
        if (!file.id) {
            const url = URL.createObjectURL(file);
            window.open(url, '_blank');
            return;
        }

        setLoadingFiles([...loadingFiles, file.id]);

        downloadFileOnServer(file.id)
            .catch(() => {
                errorToast(I18n.t('FileDownloadError'));
            })
            .finally(() => {
                setLoadingFiles(loadingFiles.filter(id => id !== file.id));
            })
    }


    const removeFile = (file) => {
        setShowModal(false);
        if (!file.id) {
            setFiles(files.filter(f => f !== file));
            return;
        }

        deleteFileOnServer(file.id)
            .then(() => {
                setFiles(files.filter(f => f !== file));
                successToast(I18n.t('FileDeleteSuccess'));
            })
            .catch(() => {
                errorToast(I18n.t('FileDeleteError'));
            })
    }

    useEffect(() => {
        if (files.length <= 2) {
            setErrorMessage('');
        }
    }, [files])

    return (
        <div className={`dropzone-container ${className}`}>
            {React.isValidElement(title) ? title : <span className="input-text-title">{title}</span>}
            {React.isValidElement(subtitle) ? subtitle : <p className="input-subtext-title mb-3">{subtitle}</p>}
            <div className={`${(disabled || readOnly) ? 'dropzone--disabled' : ''} dropzone`} {...getRootProps({ style })}>
                <input {...getInputProps()}
                    disabled={(disabled || readOnly) }
                />
                <div className="dropzone-placeholder">
                    <FontAwesome name="cloud-download" size="3x" className="dropzone-placeholder-icon" />
                    <div dangerouslySetInnerHTML={{ __html: I18n.t('DropzonePlaceholder', { linkStart: '<span class="dropzone-placeholder-link">', linkEnd: '</span>' }) }} />
                </div>
            </div>
            <span className="mt-3">
                {I18n.t('DropzoneAcceptedFiles')} {mimeTypes.map((mime) => FILE_TYPE_NAME_BY_MIME[mime]).join(', ')}
            </span>
            <span>
                {I18n.t('DropzoneFileMaxSize', { size: 10 })}
            </span>
            {errorMessage && <span className="text-danger">{errorMessage}</span>}
            <aside>
                <ul className="list-unstyled w-100">
                    {files.map((file, index) => (
                        <li key={`file-${index}`} className="dropzone-file">
                            <WrapperLoader
                                loading={loadingFiles.includes(file.id)}
                                containerClassName="d-flex justify-content-between w-100 align-items-center"
                            >
                                <div className="d-flex text-truncate">
                                    <span className="dropzone-file-text" onClick={() => downloadFile(file)} >{file.name || file.filename}</span>
                                </div>
                                <div className="d-flex">
                                    <FontAwesome name="upload" className="ml-2 cursor-pointer" onClick={() => downloadFile(file)} />
                                    {!disabled && (
                                        <FontAwesome name="times" className="ml-2 cursor-pointer" onClick={() => toggleRemoveModal(file)} />
                                    )}
                                </div>
                            </WrapperLoader>
                        </li>
                    ))}
                </ul>
            </aside>
            <Modal className="modal-export" show={showModal} onHide={toggleRemoveModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{I18n.t('DropzoneSupressFile')}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="">
                    <p>{I18n.t('DropzoneSupressFileMessage')}</p>
                    <div className="d-flex justify-content-center row-gap-3">
                        <div className="w-50 d-flex p-2">
                            <CustomButton onClick={() => setShowModal(false)} icon={<FontAwesome name="arrow-left" />} small secondary />
                        </div>
                        <div className="w-50 d-flex p-2">
                            <CustomButton onClick={() => removeFile(filesToDelete)} title={I18n.t('CommonDelete')} small danger />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ORFIDropzone;
