import React, { Component } from 'react';
import eventService from 'services/eventService'
import CONSTANTS from '../../utils/constants';
import { geolocated } from "react-geolocated";
import GoogleMapReact from 'google-map-react';
import Marker from '../eventsMap/Marker';
import SearchBox from './SearchBox';
import './eventCreationMap.scss';
import { GOOGLE_MAPS_KEY } from 'services/config';
import debounce from 'lodash.debounce'

class EventCreationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draggable: false,
      coords: CONSTANTS.DEFAULT_COORDS, // Par défaut on set les coordonnées à la ville de Paris
      currentZoom: CONSTANTS.DEFAULT_ZOOM.zoom,
      userPosition: false,
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      geoCoder: null,
      address: null,
      places: [],
      currentCoordLocalization: null
    };

    if (this.props.edit || this.props.value) {
      this.state = {
        ...this.state,
        coords: {
          lat: parseFloat(this.props.value.latitude),
          lng: parseFloat(this.props.value.longitude)
        },
        address: `${this.props.value.address} ${this.props.value.zipcode} ${this.props.value.city}`,
      }
    }
    this.generateAddressDebounce = debounce(this.generateAddress.bind(this), 500);
  }

  componentWillMount() {
    if (!this.props.edit && ('geolocation' in navigator) && !this.props.value) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.updateSelectedAddress(position.coords);
        this.generateAddress();
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.edit && ('geolocation' in navigator) && this.state.currentCoordLocalization === null && !this.props.value) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({currentCoordLocalization: position.coords});
        this.updateSelectedAddress(position.coords);
        this.generateAddress();
      });
    }
  }

  handleAdressFromCoordinates = (address) => {
    this.updateSelectedAddress({
      latitude: address.geometry.location.lat(),
      longitude: address.geometry.location.lng()
    });
    this.setState({address: address.formatted_address});
    this.props.onChange(eventService.generateAddressForCreation(address, address.geometry.location.lat(), address.geometry.location.lng()))
  };

  updateSelectedAddress = (coord) => {
    this.setState({
      coords: {
        lat: coord.latitude,
        lng: coord.longitude
      }
    });
  };

  onMarkerInteraction = (childKey, childProps, mouse) => {
    if (!this.props.disabled) {
      this.setState({
        draggable: false,
        coords: {
          lat: mouse.lat,
          lng: mouse.lng
        }
      });
      this.generateAddressDebounce();
    }
  };

  onMarkerDraggable = () => {
    if (!this.props.disabled) {
      this.setState({
        draggable: true
      });
      this.generateAddressDebounce();
    }
  };

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });

    if (!this.props.edit) {
      this.generateAddressDebounce();
    }
  };

  generateAddress = () => {
    const {
      mapApi
    } = this.state;

    if (mapApi) {
      const geocoder = new mapApi.Geocoder();

      geocoder.geocode({ 'location': { lat: this.state.coords.lat, lng: this.state.coords.lng }} , (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.setState({ address: results[0].formatted_address });
            this.props.onChange(eventService.generateAddressForCreation(results[0], this.state.coords.lat, this.state.coords.lng))
          }
        }
      });
    }
  }

  render() {
    return (
        <div className="map-creation">
          {this.state.mapApiLoaded &&
          <SearchBox map={this.state.mapInstance}
                     mapApi={this.state.mapApi}
                     updateMarker={this.handleAdressFromCoordinates}
                     value={this.state.address}
                      disabled={this.props.disabled}/>}
          <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_KEY,
                libraries: 'places'
              }}
              onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
              center={this.state.coords}
              zoom={this.state.currentZoom}
              draggable={this.state.draggable}
              onChildMouseUp={this.onMarkerDraggable}
              onChildMouseMove={this.onMarkerInteraction}
              onChildMouseDown={this.onMarkerInteraction}
          >
            <Marker
                lat={this.state.coords.lat}
                lng={this.state.coords.lng}/>
          </GoogleMapReact>
        </div>
    );
  }
}

export default geolocated()(EventCreationMap);