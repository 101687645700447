import React from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "./checkboxField.scss";

const CheckboxField = ({
    name,
    value,
    onChange,
    label,
    className,
}) => {
    return (
        <div className={`${className} no-icon`}>
            <CheckboxTree
                nodes={[{
                    value: name,
                    label: label,
                    icon: null,
                }]}
                checked={value === true ? [name] : []}
                onCheck={() => onChange(!value)}
            />
        </div>
    )
}

export default CheckboxField;