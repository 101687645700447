import React, { Component } from 'react';
import images from 'resources/assets/images';
import { withRouter } from "react-router-dom";

class Logo extends Component {
    render() {
        return (
            <img alt="ORFI"
                 className="logo-small"
                 src={images.homeLogo}
                onClick={() => this.props.history.push('/accueil')}/>
        );
    }
}

export default withRouter(Logo);