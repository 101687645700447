import I18n from 'i18n-js';
import fr from 'resources/i18n/fr';

I18n.locale = 'fr';

I18n.fallbacks = true;
I18n.translations = {
  fr
};

export default I18n;
