import React from 'react'

const EventInputInfo = ({
    title,
    subtitle,
    value = '',
    onChange,
    choices = [],
    disabled
}) => (
    <div>
        <p className="input-text-title mt-3 mb-0">{title}</p>
        <select
            className="custom-select form-control"
            onChange={onChange}
            value={value || ''}
            disabled={disabled}
        >
            <option value="">{subtitle}</option>
            {choices.map(choice => (
                <option value={choice.id}>{choice.label}</option>
            ))}
        </select>
    </div>
)

export default EventInputInfo;