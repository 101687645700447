import React from "react";
import IconCard from "components/iconCard/IconCard";

const EventInfoCard = ({
    id,
    onClick,
    iconType,
    description,
    selected,
    inline,
    disabled
}) => (
    <IconCard
        onClick={onClick}
        key={id}
        iconType={iconType}
        description={description}
        idSelected={selected}
        inline={inline}
        edit={!inline}
        disabled={disabled}
    />
)

export default EventInfoCard;