import React, { memo } from 'react';
import I18n from 'resources/i18n/I18n';
import moment from 'moment';
import CONSTANTS from '../../utils/constants';
import Form from 'react-bootstrap/Form';
import { Multiselect } from 'react-widgets'
import './status.scss';
import { useSelector } from 'react-redux';
import ORFIDropzone from 'components/files/Dropzone';
import EventApi from 'services/eventApi';
import RPGDInfo from 'components/files/RPGDInfo';
import { errorToast } from 'utils/toast';

const Status = ({
    editStatus,
    status,
    readOnly,
    processes,
    complaints,
    files,
    setFiles,
    isFormDisabled,
}) => {
    const [statusFiles, setStatusFiles] = React.useState(files);
    const currentUser = useSelector(state => state.userReducer.user);
    const editComplaints = (complaints) => {
        complaints = complaints.map(x => x.id);
        editStatus({ ...status, complaintList: complaints });
    };

    const editProcesses = (processes) => {
        processes = processes.map(x => x.id);
        editStatus({ ...status, processList: processes });
    };

    const settingStatusFiles = (files) => {
        if (setFiles) {
            setFiles(files);
        }
        setStatusFiles(files);
    }

    const statusConst = CONSTANTS.STATUS.find(x => x.id === status.status);
    const style = {
        backgroundColor: statusConst.color
    };

    return (
        <div className="container card-status mb-3">
            <div className="label mb-2" style={style}>{statusConst.label}</div>
            <div className="author mb-2">
                <div>{I18n.t('EventBy')} {status.idUser ? status.idUser : currentUser.label}</div>
                <div>
                    {moment(status.date).format('DD/MM/YYYY')}
                </div>
            </div>
            {status.status !== CONSTANTS.STATUS_LIST.NEW &&
                <div>
                    <div className="desc">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>{I18n.t('EventCommentTitle')}</Form.Label>
                            <Form.Control as="textarea"
                                rows="3"
                                placeholder={I18n.t('EventComment')}
                                defaultValue={status && status.comment ? status.comment : ''}
                                onChange={(internComment) => editStatus({ ...status, comment: internComment.target.value })}
                                readOnly={readOnly} />
                        </Form.Group>
                    </div>
                </div>
            }
            {(status.status === CONSTANTS.STATUS_LIST.PROCESSING ||
                status.status === CONSTANTS.STATUS_LIST.VALIDATED) &&
                <div>
                    <div>
                        <span className="subtitle">{I18n.t('Procedure')}</span>
                        <Multiselect
                            data={processes}
                            valueField='id'
                            textField='label'
                            disabled={readOnly}
                            defaultValue={status && status.processes ? status.processes : []}
                            onChange={editProcesses}
                        />
                    </div>
                    <div>
                        <span className="subtitle">{I18n.t('Complaint')}</span>
                        <Multiselect
                            data={complaints}
                            valueField='id'
                            textField='label'
                            disabled={readOnly}
                            defaultValue={status && status.complaints ? status.complaints : []}
                            onChange={editComplaints}
                        />
                    </div>
                    <div>
                        <span className="subtitle">{I18n.t('ITT')}</span>
                        <Form.Group>
                            <Form.Control
                                defaultValue={status && status.itt ? status.itt : ''}
                                onChange={(itt) => editStatus({ ...status, itt: itt.target.value })}
                                readOnly={readOnly} />
                        </Form.Group>
                    </div>
                    <div>
                        <span className="subtitle">{I18n.t('Prejudice')}</span>
                        <Form.Group>
                            <Form.Control
                                defaultValue={status && status.prejudice ? status.prejudice : ''}
                                onChange={(prejudice) => editStatus({ ...status, prejudice: prejudice.target.value })}
                                readOnly={readOnly} />
                        </Form.Group>
                    </div>

                    {currentUser.group.enableAttachments && (
                        <ORFIDropzone
                            className='mt-3'
                            files={statusFiles}
                            setFiles={settingStatusFiles}
                            title={I18n.t('EventFiles')}
                            subtitle={<RPGDInfo />}
                            downloadFileOnServer={EventApi.downloadFile}
                            deleteFileOnServer={EventApi.deleteFile}
                            disabled={isFormDisabled}
                            readOnly={readOnly}
                        />
                    )}
                </div>
            }
        </div>
    );
}

export default memo(Status);