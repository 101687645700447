import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import FontAwesome from 'react-fontawesome';
import ModalDelete from '../ModalDelete';
import '../configuration.scss';
import I18n from '../../../resources/i18n/I18n';
import ModalEstateCreation from "./ModalEstateCreation";
import ModalEstateEdition from "./ModalEstateEdition";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import patrimonyApi from 'services/patrimonyApi';
import WrapperLoader from 'components/loader/WrapperLoader';
import { SMALL_BUTTON_LOADER_SIZE } from 'constants/loaderConstants';

const LabelEditionTreePatrimonies = ({
    searchValue,
    onSearchValueChange,
    patrimonies,
    group,
    error,
    createPatrimony,
    createSector,
    createAgency,
    deletePatrimony,
    deleteSector,
    deleteAgency,
    editPatrimony,
    editSector,
    editAgency,
    importPatrimony,
}) => {
    const [showModalEdition, setShowModalEdition] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalCreation, setShowModalCreation] = useState(false);
    const [itemToCreate, setItemToCreate] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [titleModal, setTitleModal] = useState(null);
    const [concernedSectors, setConcernedSectors] = useState([]);
    const [loadingState, setLoadingState] = useState({
        downloadPatrimony: false,
    });

    useEffect(() => {
        const concernedSectors = []
        if (patrimonies) {
            const regex = new RegExp(`(${searchValue})`, 'gi')
            patrimonies.forEach((item) => {
                item.sectors.forEach((sector) => {
                    sector.agencies.forEach((agency) => {
                        const parts = agency.label.split(regex);
                        parts.filter(part => part).forEach((part, i) => {
                            regex.test(part) && concernedSectors.push(`${sector.id}-sector`);
                        })
                    })
                })
            })
            setConcernedSectors(concernedSectors);
        }
        if (!concernedSectors.length || !searchValue) {
            setConcernedSectors([]);
        }
    }, [searchValue, patrimonies]);

    const toggleModalCreation = () => {
        setShowModalCreation(!showModalCreation);
    };

    const getTitleModal = (type) => {
        switch (type) {
            case 'patrimony':
                return group.labelPatrimony;
            case 'sector':
                return group.labelSector;
            case 'agency':
                return group.labelAgency;
            default:
                return null;
        }
    }

    const onCreateLabel = (e, item, itemTypeParent) => {
        if (e) {
            e.stopPropagation();
        }
        setItemToCreate({ idParent: item && item.id ? item.id : null, itemType: itemTypeParent });
        setTitleModal(getTitleModal(itemTypeParent) ? `${I18n.t('CreateConfirmTitleWithType')} ${getTitleModal(itemTypeParent)}` : I18n.t('CreateConfirmTitle'));
        toggleModalCreation();
    };

    const onLabelCreation = (label, code) => {
        const newItem = { ...itemToCreate, label: label, code: code };
        switch (itemToCreate.itemType) {
            case 'patrimony':
                return createPatrimony(newItem).then(result => {
                    if (!result || !result.description) {
                        setItemToCreate(null);
                    }
                    return result;
                });
            case 'sector':
                return createSector(newItem).then(result => {
                    if (!result || !result.description) {
                        setItemToCreate(null);
                    }
                    return result;
                });
            case 'agency':
                return createAgency(newItem).then(result => {
                    if (!result || !result.description) {
                        setItemToCreate(null);
                    }
                    return result;
                });
            default:
                break;
        }
    };

    const toggleModalDelete = () => {
        setShowModalDelete(!showModalDelete);
    };

    const onDeleteLabel = (e, item, itemType) => {
        if (e) {
            e.stopPropagation();
        }

        setItemToDelete({ ...item, itemType: itemType });
        setTitleModal(`${I18n.t('DeleteConfirmTitleWithType')} ${getTitleModal(itemType)}`);
        toggleModalDelete();
    };

    const deleteLabel = () => {
        switch (itemToDelete.itemType) {
            case 'patrimony':
                deletePatrimony(itemToDelete);
                break;
            case 'sector':
                deleteSector(itemToDelete);
                break;
            case 'agency':
                deleteAgency(itemToDelete);
                break;
            default:
                break;
        }
        setItemToDelete(null);
    };

    const toggleModalEdition = () => {
        setShowModalEdition(!showModalEdition);
    };

    const onEditLabel = (e, item, itemType) => {
        if (e) {
            e.stopPropagation();
        }
        setItemToEdit({ ...item, itemType: itemType });
        setTitleModal(`${I18n.t('EditConfirmTitleWithYpe')} ${getTitleModal(itemType)}`);
        toggleModalEdition();
    };

    const modifyItemCode = (code) => {
        setItemToEdit({ ...itemToEdit, code: code });
    };

    const modifyItemLabel = (label) => {
        setItemToEdit({ ...itemToEdit, label: label });
    };

    const editLabel = () => {
        //Appel à l'édition du parent (call WS)
        switch (itemToEdit.itemType) {
            case 'patrimony':
                return editPatrimony(itemToEdit).then(result => {
                    if (!result || !result.description) {
                        setItemToEdit(null);
                    }
                    return result;
                });
            case 'sector':
                return editSector(itemToEdit).then(result => {
                    if (!result || !result.description) {
                        setItemToEdit(null);
                    }
                    return result;
                });
            case 'agency':
                return editAgency(itemToEdit).then(result => {
                    if (!result || !result.description) {
                        setItemToEdit(null);
                    }
                    return result;
                });
            default:
                break;
        }
    };

    const onActivateAgency = (e, agency) => {
        const newAgency = { ...agency, active: !agency.active };
        editAgency(newAgency);
    }

    const renderButton = (item, itemType, itemTypeChild = null) => (
        <div className="d-flex gap-x-2">
            {
                itemTypeChild ?
                    <div className="action-button small" onClick={(e) => onCreateLabel(e, item, itemTypeChild)}>
                        <FontAwesome name="plus" />
                    </div>
                    :
                    <div className="action-button small" onClick={(e) => onActivateAgency(e, item)}>
                        <FontAwesome name="ban" />
                    </div>
            }
            <div className="action-button small" onClick={(e) => onDeleteLabel(e, item, itemType)}>
                <FontAwesome name="trash" />
            </div>
            <div className="action-button small" onClick={((e) => onEditLabel(e, item, itemType))}>
                <FontAwesome name="pencil" />
            </div>
        </div>
    )

    const sortByLabel = (a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
        }
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
        }
        return 0;
    }

    const renderAgency = (sector) => {
        return (sector.agencies.sort(sortByLabel).map((agency) => {
            return <Accordion.Collapse key={agency.id} eventKey={`${sector.id}-sector`}>
                <Card.Body>
                    <div className="configuration container d-flex justify-content-between align-items-center m-0" style={{ backgroundColor: agency.active ? "White" : "LightGray" }}>
                        <span className="category-subtitle">
                            {renderHighlight(agency.label, searchValue || '')}
                            {!agency.active && <i> {I18n.t('Disabled')}</i>}
                        </span>
                        {renderButton(agency, 'agency')}
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        }));
    };

    const renderSectors = (patrimony) => {
        return (patrimony.sectors.sort(sortByLabel).map((sector) => {
            const eventKey = `${sector.id}-sector`
            const isOpen = concernedSectors && !!concernedSectors.find((e => e === eventKey))
            const accordionAttributes = isOpen ? { activeKey: eventKey } : {}
            return <Accordion {...accordionAttributes} key={sector.id}>
                <Card.Body className='p-0'>
                    <Card key={sector.id}>
                        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                            <div className="d-flex justify-content-between align-content-center">
                                {renderHighlight(sector.label, searchValue || '')}
                                {renderButton(sector, 'sector', 'agency')}
                            </div>
                        </Accordion.Toggle>
                        {renderAgency(sector)}
                    </Card>
                </Card.Body>
            </Accordion>
        }));
    };

    const exportPatrimony = () => {
        setLoadingState({ ...loadingState, downloadPatrimony: true });
        patrimonyApi.exportPatrimony()
            .finally(() => setLoadingState({ ...loadingState, downloadPatrimony: false }));
    };

    const renderHighlight = (text, highlight) => {
        if (!highlight.trim()) {
            return <span>{text}</span>
        }
        const regex = new RegExp(`(${highlight})`, 'gi')
        const parts = text.split(regex)
        return (
            <span>
                {parts.filter(part => part).map((part, i) => (
                    regex.test(part) ? <mark className='px-0' key={i}>{part}</mark> : <span key={i}>{part}</span>
                ))}
            </span>
        )
    }
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-end gap-x-2">
                <Form.Group className="col-6 ">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesome name="search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            placeholder={I18n.t('PatrimonyEditionSearchPlaceholder')}
                            onChange={onSearchValueChange} />
                    </InputGroup>
                </Form.Group>
                <div className="action-button small patrimony" onClick={importPatrimony}>
                    <span className="icon-csv" />
                </div>
                <WrapperLoader
                    loading={loadingState.downloadPatrimony}
                    className="overlay-wrapper__button--small"
                    loadingElementsSize={SMALL_BUTTON_LOADER_SIZE}
                >
                    <div className="action-button small" onClick={exportPatrimony}>
                        <FontAwesome name="download" />
                    </div>
                </WrapperLoader>
                <div className="action-button small" onClick={(e) => onCreateLabel(e, null, 'patrimony')}>
                    <FontAwesome name="plus" />
                </div>
            </div>
            <div className="row">
                <p className="error text-center"> {error} </p>
                {patrimonies && patrimonies.sort(sortByLabel).map((item) => {
                    return (
                        <Card className="w-100 row mb-3" key={item.id}>
                            <Card.Header className="bg-transparent border-0 font-weight-bold primary-text-color">
                                <div className="d-flex justify-content-between align-items-center">
                                    {renderHighlight(item.label, searchValue || '')}
                                    {renderButton(item, 'patrimony', 'sector')}
                                </div>
                            </Card.Header>
                            {renderSectors(item)}
                        </Card>
                    )
                })}
            </div>
            <ModalEstateCreation
                showModal={showModalCreation}
                toggleModal={toggleModalCreation}
                createLabel={onLabelCreation}
                title={titleModal}
            />
            <ModalEstateEdition
                showModal={showModalEdition}
                toggleModal={toggleModalEdition}
                item={itemToEdit}
                editLabel={editLabel}
                title={titleModal}
                modifyItemCode={modifyItemCode}
                modifyItemLabel={modifyItemLabel}
            />
            <ModalDelete
                showModal={showModalDelete}
                toggleModal={toggleModalDelete}
                deleteLabel={deleteLabel}
                title={titleModal}
            />
        </div>
    )
}

export default LabelEditionTreePatrimonies;
