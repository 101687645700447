import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autocompletion from './Autocompletion';
import Criteria from './Criteria';
import CheckboxTree from 'react-checkbox-tree';
import FontAwesome from 'react-fontawesome';
import I18n from '../../resources/i18n/I18n';
import TypeApi from '../../services/typeApi';
import PatrimonyApi from '../../services/patrimonyApi';
import UserApi from '../../services/userApi';
import AddressApi from '../../services/addressApi';
import SiteApi from '../../services/siteApi';
import CONSTANTS from '../../utils/constants';
import { fetchSiteSuccess } from "../../store/actions/resourceActions";
import { addSiteFilter, removeSiteFilter } from "../../store/actions/searchActions";
import { fetchTypesSuccess, fetchPatrimoniesSuccess } from 'store/actions/resourceActions';
import {
    updatePatrimonyFilter, addCityFilter, removeCityFilter,
    addAuthorFilter, removeAuthorFilter,
    addStatusFilter, removeStatusFilter, updateSubtypeFilter,
    updateStartDateFilter, updateEndDateFilter
} from 'store/actions/searchActions';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/fr';
import './filters.scss';
import './autocomplete.scss';
import { errorToast } from '../../utils/toast';
import { PulseLoader } from 'react-spinners';



class Filters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: (props.filters && this.props.filters.idSubtype) || [],
            expanded: [],
            cities: [],
            authors: [],
            patrimoniesTree: props.resources.patrimoniesTree || [],
            checkedPatrimonies: props.filters.idAgency || [],
            expandedPatrimonies: [],
            loading: false
        };
    }

    fetchSites = () => {
        if (this.props.resources.sites.length === 0) {
            return SiteApi.getSiteList().then((response) =>
                this.props.dispatch(fetchSiteSuccess(response))
            ).catch(() => {
                errorToast(I18n.t('ErrorFetchSites'))
            })
        }
    }

    fetchTypeTree = () => {
        if (this.props.resources.typesTree.length === 0) {
            TypeApi.getTypeList().then((response) =>
                this.props.dispatch(fetchTypesSuccess(response))
            ).catch(() => {
                errorToast(I18n.t('ErrorFetchTypes'))
            })
        }
    }

    fetchCities = () => {
        if (this.state.cities.length === 0) {
            return AddressApi.getCitiesList().then((response) => {
                let cities = [];
                response.forEach(c => {
                    if (!cities.find(city => city.id === c["address.zipcode"] && city.label === `${c["address.city"]} - ${c["address.zipcode"]}`)) {
                        cities.push({
                            label: `${c["address.city"]} - ${c["address.zipcode"]}`,
                            id: c["address.zipcode"],
                            name: c["address.city"],
                        })
                    }
                });
                cities.sort(function (first, second) {
                    return first.id < second.id ? -1 : 1;
                })
                this.setState({ cities: cities })
            }).catch(() => {
                errorToast(I18n.t('ErrorFetchCities'))
            })
        }
    }

    fetchPatrimonies = () => {

        const getTree = (records) => records.sort((a, b) => (a.label > b.label) ? 1 : -1).map(patrimony => ({
            label: patrimony.label,
            value: `patrimony-${patrimony.id}`,
            title: patrimony.label,
            className: 'patrimony-filter-node',
            children: patrimony.sectors.sort((a, b) => (a.label > b.label) ? 1 : -1).map(sector => ({
                label: sector.label,
                value: `sector-${sector.id}`,
                title: sector.label,
                className: 'patrimony-filter-node',
                children: sector.agencies.sort((a, b) => (a.label > b.label) ? 1 : -1).map(agency => ({
                    label: agency.label,
                    value: agency.id,
                    title: agency.label,
                    className: 'patrimony-filter-node'
                }))
            }))
        }))

        if (this.props.resources.patrimonies.length === 0) {
            return PatrimonyApi.getPatrimonyList({ active: 1 }).then((response) => {
                this.props.dispatch(fetchPatrimoniesSuccess(response))
                this.setState({ patrimoniesTree: getTree(response.records) })
            }).catch(() => {
                errorToast(I18n.t('ErrorFetchPatrimonies'))
            })
        }
        
        this.setState({ patrimoniesTree: getTree(this.props.resources.patrimonies) })
    }

    fetchUsers = () => {
        if (this.state.authors.length === 0) {
            return UserApi.getUsersList().then((response) => {
                this.setState({
                    authors: response.map(user => ({
                        label: `${user.firstname} ${user.lastname}`,
                        id: user.id
                    }))
                })
            }).catch(() => {
                errorToast(I18n.t('ErrorFetchAuthors'))
            })
        }
    }

    selectSite = (site) => {
        this.props.dispatch(addSiteFilter(site.label));
    };

    removeSite = (site) => {
        this.props.dispatch(removeSiteFilter(site.label));
    };

    selectCity = (city) => {
        this.props.dispatch(addCityFilter(`${city.name}--${city.id}`));
    };

    removeCity = (city) => {
        this.props.dispatch(removeCityFilter(`${city.name}--${city.id}`));
    };

    selectAuthor = (author) => {
        this.props.dispatch(addAuthorFilter(author.id));
    };

    removeAuthor = (author) => {
        this.props.dispatch(removeAuthorFilter(author.id));
    };

    updateStatusFilter = (e) => {
        if (e.target.checked) {
            this.props.dispatch(addStatusFilter(e.target.value));
        } else {
            this.props.dispatch(removeStatusFilter(e.target.value));
        }
    };

    updateTypeFilter = (checked) => {
        this.setState({ checked });
        this.props.dispatch(updateSubtypeFilter(checked));
    };

    updatePatrimonyFilter = (checkedPatrimonies) => {
        this.setState({ checkedPatrimonies });
        this.props.dispatch(updatePatrimonyFilter(checkedPatrimonies));
    };

    selectStartDate = (date) => {
        this.props.dispatch(updateStartDateFilter(date));
    };

    selectEndDate = (date) => {
        this.props.dispatch(updateEndDateFilter(date));
    };

    onDateChanged = (value, callback) => {
        const currentDate = new Date();

        const date = moment(value);

        if (!date.isValid() || date.isAfter(currentDate)) {
            return;
        }

        callback(date);
    }

    render() {
        const { typesTree, sites } = this.props.resources;
        const statusList = CONSTANTS.STATUS;
        const currentDate = new Date();

        return (
            <div>
                <div className="d-flex d-md-none justify-content-end mt-4 close-modal z">
                    <FontAwesome className="fa-lg" name="times" onClick={() => this.props.toggleFilters(false)} />
                </div>
                <div className="header-criteria text-uppercase">
                    <span>{I18n.t('FiltersTitle')}</span>
                </div>
                <div className='d-flex flex-column position-relative'>
                    {this.props.loading && (
                        <div className='position-absolute d-flex justify-content-center align-items-center w-100 h-100 overlay'>
                            <PulseLoader className="spinner position-absolute" color="white" />
                        </div>
                    )}

                    {this.props.user.group && this.props.user.group.oldDisplay &&
                        <Criteria
                            title={I18n.t('SiteCriteria')}
                            height={(this.props.filters && this.props.filters.site && this.props.filters.site.length > 0) ? "auto" : 0}
                            onToggle={this.fetchSites}
                            defaultToggle={sites.length}
                        >
                            <Autocompletion
                                suggestions={sites}
                                initialValues={(this.props.filters && this.props.filters.site) || []}
                                onSelect={this.selectSite}
                                onRemove={this.removeSite} />
                        </Criteria>
                    }
                    <Criteria
                        height="auto"
                        title={I18n.t('DateCriteria')}
                    >
                        <span>
                            <span className="subtitle">{I18n.t('StartDate')}</span>
                            <Datetime
                                className="mb-3"
                                locale="fr-ca"
                                defaultValue={
                                    this.props.filters && this.props.filters.startDate ? moment(this.props.filters.startDate).toDate() 
                                    : moment(new Date()).add(-1, 'months').toDate()
                                }
                                isValidDate={(d) => d.isBefore(currentDate)}
                                timeFormat={false}
                                onChange={(value) => this.onDateChanged(value, this.selectStartDate)}
                            />
                        </span>
                        <span>
                            <span className="subtitle">{I18n.t('EndDate')}</span>
                            <Datetime locale="fr-ca"
                                defaultValue={this.props.filters && this.props.filters.endDate ? moment(this.props.filters.endDate) : new Date()}
                                isValidDate={(d) => d.isBefore(currentDate)}
                                timeFormat={false}
                                onChange={(value) => this.onDateChanged(value, this.selectEndDate)}
                            />
                        </span>
                    </Criteria>
                    <Criteria
                        height={(this.props.filters && this.props.filters.idSubtype && this.props.filters.idSubtype.length > 0) ? "auto" : 0}
                        title={I18n.t('TypeCriteria')}
                        onToggle={this.fetchTypeTree}
                    >
                        <CheckboxTree
                            nodes={typesTree}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={checked => this.updateTypeFilter(checked)}
                            onExpand={expanded => this.setState({ expanded })}
                            icons={{
                                check: <FontAwesome className="rct-icon rct-icon-check" name="check-square" />,
                                uncheck: <FontAwesome className="rct-icon rct-icon-uncheck" name="far square" />,
                                halfCheck: <FontAwesome className="rct-icon rct-icon-half-check" name="check-square" />,
                                expandClose: <FontAwesome className="rct-icon rct-icon-expand-close" name="chevron-right" />,
                                expandOpen: <FontAwesome className="rct-icon rct-icon-expand-open" name="chevron-down" />,
                                expandAll: <FontAwesome className="rct-icon rct-icon-expand-all" name="plus-square" />,
                                collapseAll: <FontAwesome className="rct-icon rct-icon-collapse-all" name="minus-square" />,
                                parentClose: null,
                                parentOpen: null,
                                leaf: null
                            }}
                        />
                    </Criteria>
                    <Criteria
                        height={(this.props.filters && this.props.filters.status && this.props.filters.status.length > 0) ? "auto" : 0}
                        title={I18n.t('StatusCriteria')}
                    >
                        {statusList.map((status) => (
                            <div key={status.id}>
                                <input id={status.id}
                                    className="filter-checkbox"
                                    type="checkbox"
                                    checked={(this.props.filters && this.props.filters.status && this.props.filters.status.find((cstatus) => cstatus === status.id))}
                                    value={status.id}
                                    onChange={this.updateStatusFilter} />
                                <label htmlFor={status.id}>{status.label}</label>
                            </div>
                        ))}
                    </Criteria>
                    <Criteria
                        title={I18n.t('CityCriteria')}
                        height={(this.props.filters && this.props.filters.city && this.props.filters.city.length > 0) ? "auto" : 0}
                        onToggle={this.fetchCities}
                    >
                        <Autocompletion
                            suggestions={this.state.cities.filter(city => !this.props.filters.city || !this.props.filters.city.includes(`${city.name}--${city.id}`))}
                            onSelect={this.selectCity}
                            initialValues={(this.props.filters && this.props.filters.city) || []}
                            onRemove={this.removeCity} />
                    </Criteria>
                    {
                        this.props.user.roles && this.props.user.roles.indexOf('ORFI-GARDIEN') === -1 &&
                        <Criteria
                            title={I18n.t('AuthorCriteria')}
                            onToggle={this.fetchUsers}
                            defaultToggle={this.props.filters.author && this.props.filters.author.length}
                        >
                            <Autocompletion
                                suggestions={this.state.authors.filter(author => !this.props.filters.author || !this.props.filters.author.includes(author.id))}
                                onSelect={this.selectAuthor}
                                getInitialValues={() => (this.props.filters && this.props.filters.author ? this.state.authors.filter(author => this.props.filters.author.includes(author.id)) : [])}
                                onRemove={this.removeAuthor} />
                        </Criteria>
                    }
                    {this.props.user.group && !this.props.user.group.oldDisplay &&
                        <Criteria
                            title={I18n.t('PatrimonyCriteria')}
                            onToggle={this.fetchPatrimonies}
                            defaultToggle={this.props.filters.idAgency && this.props.filters.idAgency.length}
                        >
                            <CheckboxTree
                                nodes={this.state.patrimoniesTree}
                                checked={this.state.checkedPatrimonies}
                                expanded={this.state.expandedPatrimonies}
                                onCheck={checkedPatrimonies => this.updatePatrimonyFilter(checkedPatrimonies)}
                                onExpand={expandedPatrimonies => this.setState({ expandedPatrimonies })}
                                expandOnClick
                                icons={{
                                    check: <FontAwesome className="rct-icon rct-icon-check" name="check-square" />,
                                    uncheck: <FontAwesome className="rct-icon rct-icon-uncheck" name="far square" />,
                                    halfCheck: <FontAwesome className="rct-icon rct-icon-half-check" name="check-square" />,
                                    expandClose: <FontAwesome className="rct-icon rct-icon-expand-close" name="chevron-right" />,
                                    expandOpen: <FontAwesome className="rct-icon rct-icon-expand-open" name="chevron-down" />,
                                    expandAll: <FontAwesome className="rct-icon rct-icon-expand-all" name="plus-square" />,
                                    collapseAll: <FontAwesome className="rct-icon rct-icon-collapse-all" name="minus-square" />,
                                    parentClose: null,
                                    parentOpen: null,
                                    leaf: null
                                }}
                            />
                        </Criteria>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.searchReducer,
        resources: state.resourceReducer,
        user: state.userReducer.user
    };
};

export default connect(mapStateToProps)(Filters);
