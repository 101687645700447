import { saveAs } from "file-saver";
import I18n from "i18n-js";
import callApi from "services";
import { errorToast } from "utils/toast";

export const getExportStatus = (jobList) => {
    return callApi(`exports/progress`, 'POST', JSON.stringify({
        jobList
    }));
}

export const downloadExport = (exportId) => {
    return callApi(`exports/${exportId}/download`, 'GET')
    .then(async (response) => {
        saveAs(await response.blob(), response.headers.get('Content-Disposition').split('filename=')[1]);
    })
    .catch(() => {
        errorToast(I18n.t('ErrorExport'));
    });
}