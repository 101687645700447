import { ResourceActions } from '../actions/resourceActions';

const initialState = {
    types: [],
    typesTree: [],
    categories: [],
    status: [],
    sites: [],
    processes: [],
    complaints: [],
    locations: [],
    victims: [],
    patrimonies: []
};

/**
 * Reducer pour gérer les listes statiques de type, soustype etc
 * @param {*} state
 * @param {*} action
 */
export function resourceReducer(state = initialState, action) {
    switch (action.type) {
        case ResourceActions.FETCH_TYPES_SUCCESS:
            //construction de l'arbre des types
            let types = [];
            let categories = [];
            action.types.forEach((type) => {
                let node = {
                    value: `type-${type.id}`,
                    label: type.label
                };
                let children = [];
                type.subtypes.forEach((subtype) => {
                    children.push({
                        value: subtype.id,
                        label: subtype.label
                    });
                    categories = categories.concat(subtype.categories);
                });
                node.children = children;
                types.push(node);
            });
            return { ...state, types: action.types, typesTree: types, categories: categories };
        case ResourceActions.FETCH_PROCESSES_SUCCESS:
            return { ...state, processes: action.processes };
        case ResourceActions.EDIT_PROCESSES_SUCCESS:
            const index = state.processes.findIndex(x => x.id === action.process.id);
            const processes = [...state.processes];
            processes[index] = action.process;
            return {
                ...state,
                processes: processes
            };
        case ResourceActions.DELETE_PROCESSES_SUCCESS:
            return {
                ...state,
                processes: state.processes.filter(process => process.id !== action.process.id)
            };
        case ResourceActions.ADD_PROCESSES_SUCCESS:
            return {
                ...state,
                processes: [...state.processes, action.process]
            };

        case ResourceActions.FETCH_COMPLAINTS_SUCCESS:
            return { ...state, complaints: action.complaints };
        case ResourceActions.EDIT_COMPLAINTS_SUCCESS:
            const indexComplaint = state.complaints.findIndex(x => x.id === action.complaint.id);
            const complaints = [...state.complaints];
            complaints[indexComplaint] = action.complaint;
            return {
                ...state,
                complaints: complaints
            };
        case ResourceActions.DELETE_COMPLAINTS_SUCCESS:
            return {
                ...state,
                complaints: state.complaints.filter(complaint => complaint.id !== action.complaint.id)
            };
        case ResourceActions.ADD_COMPLAINTS_SUCCESS:
            return {
                ...state,
                complaints: [...state.complaints, action.complaint]
            };
        case ResourceActions.FETCH_SITES_SUCCESS:
            return { ...state, sites: action.sites };
        case ResourceActions.FETCH_LOCATIONS_SUCCESS:
            return { ...state, locations: action.locations };
        case ResourceActions.FETCH_VICTIMS_SUCCESS:
            return { ...state, victims: action.victims };
        case ResourceActions.FETCH_PATRIMONIES_SUCCESS:
            return { ...state, patrimonies: action.patrimonies.records ?  action.patrimonies.records : []};
        default:
            return state;
    }
}