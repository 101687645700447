import React, { useEffect, useState } from 'react';
import images from 'resources/assets/images';
import Image from 'react-bootstrap/Image';
import IconCard from 'components/iconCard/IconCard'
import I18n from 'resources/i18n/I18n';
import { Redirect } from "react-router-dom";
import './home.scss';
import { resetFilters } from "../../store/actions/searchActions";
import { connect, useDispatch, useSelector } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.userReducer.user);
  const [redirectPath, setRedirectPath] = useState(null);
    
  useEffect(() => {
    dispatch(resetFilters())
  }, [dispatch]);

  const goTo = (path) => {
    setRedirectPath(path);
  };

  if (redirectPath) {
    return (<Redirect to={redirectPath} push />)
  }

  return (
    <div className='container-fluid'>
      <div className='row justify-content-center'>
        <Image className='logo' src={images.homeLogo} />
      </div>
      <div className="row justify-content-center row-container">
        <IconCard
          onClick={() => { goTo('/signalement') }}
          iconType="icon-warning"
          title={I18n.t('HomeScreenTitleReport')}
          description={I18n.t('HomeScreenDescriptionReport')}
        />
        <IconCard
          onClick={() => { goTo('/consulter') }}
          iconType="icon-pictoSearch"
          title={I18n.t('HomeScreenTitleSearch')}
          description={I18n.t('HomeScreenDescriptionSearch')}
          displayNotification
        />
        <IconCard
          onClick={() => { goTo('/statistiques') }}
          iconType="icon-bar-chart"
          title={I18n.t('HomeScreenTitleStats')}
          description={I18n.t('HomeScreenDescriptionStats')}
        />
        <IconCard
          onClick={() => { goTo('/aide') }}
          iconType="icon-help"
          title={I18n.t('HomeScreenTitleHelp')}
          description={I18n.t('HomeScreenDescriptionHelp')}
        />
        {currentUser.roles && currentUser.roles.indexOf('ORFI-ADMIN') > -1 &&
          <IconCard
            onClick={() => { goTo('/configuration') }}
            iconType="icon-settings"
            title={I18n.t('HomeScreenTitleConfiguration')}
            description={I18n.t('HomeScreenDescriptionConfiguration')}
          />
        }
        {currentUser.roles && currentUser.roles.indexOf('ORFI-GESTIONNAIRE') > -1 &&
          <IconCard
            onClick={() => { goTo('/organismes') }}
            iconType="icon-settings"
            title={I18n.t('HomeScreenTitleOrganismes')}
            description={I18n.t('HomeScreenDescriptionOrganismes')}
          />
        }
      </div>
    </div>
  );
}

export default connect()(Home);