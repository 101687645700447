import React from "react";
import CustomButton from "components/buttons/CustomButton";
import I18n from '../../resources/i18n/I18n';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FontAwesome from 'react-fontawesome';

const EventSubmitButtons = ({ event, editMode, onSubmit, showError, disabled }) => {
    const history = useHistory();

    const goBack = () => {
        history.replace('/consulter');
        history.goBack()
    }

    const goToNextStep = () => {
        history.replace(`/evenement/${event.id}/status`);
    }

    return (
        <div className="submit-form-footer">
            {showError && <p className="login-error text-center mt-3">{I18n.t('ReportSubmitError')}</p>}
            <div className="row justify-content-center p-4">
                <div className="p-1">
                    <CustomButton onClick={goBack} icon={<FontAwesome name="arrow-left" />} small secondary />
                </div>
                {!disabled ? <div className="p-1">
                    <CustomButton title={I18n.t('CommonSave')} type='submit' small primary />
                </div> : <div className="p-1">
                    <CustomButton title={I18n.t('Next')} type='button' onClick={goToNextStep} small primary />
                </div>}

                {!editMode &&
                    <div className="p-1">
                        <CustomButton onClick={onSubmit} title={I18n.t('ReportSaveAndDuplicate')} small primary />
                    </div>
                }
            </div >
        </div>
    )
}

export default EventSubmitButtons;