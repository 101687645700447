import callApi from ".";
import { KEYCLOAK_PARAMS, KEYCLOAK_STATE_KEY, KEYCLOAK_STORAGE_ID_TOKEN, KEYCLOAK_URL_BEFORE_LOGIN } from "../constants/keycloakConstants";
import { loginSuccess, logout } from "../store/actions/userActions";
import { store } from "../store/configureStore";

/**
 * Redirige l'utilisateur vers Keycloak pour se déconnecter
 */
export const redirectToLogout = async () => {
    const queryParams = {
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        post_logout_redirect_uri: window.location.origin,
        id_token_hint: localStorage.getItem(KEYCLOAK_STORAGE_ID_TOKEN)
    }

    store.dispatch(logout())

    const params = new URLSearchParams();

    for (const key in queryParams) {
        params.append(key, queryParams[key]);
    }

    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_KEYCLOAK_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout?${params.toString()}`;
}

/**
 * Redirige l'utilisateur vers Keycloak avec les bons paramètres
 */
export const redirectToLogin = () => {
    localStorage.clear();
    const currentPath = window.location.pathname;
    const state = generateRandomState();
    localStorage.setItem(KEYCLOAK_STATE_KEY, state);
    localStorage.setItem(KEYCLOAK_URL_BEFORE_LOGIN, currentPath === '/' ? '/accueil' : currentPath);
    const params = new URLSearchParams();

    const queryParams = {
        ...KEYCLOAK_PARAMS,
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        redirect_uri: window.location.origin,
        state
    }

    for (const key in queryParams) {
        params.append(key, queryParams[key]);
    }
    
    window.location.href = `${process.env.REACT_APP_KEYCLOAK_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth?${params.toString()}`;
}

/**
 * Gère la callback keycloak pour authentifier l'utilisateur
 * 
 * @param string code 
 */
export const handleKeycloakCallback = (code, state) => {

    if(localStorage.getItem(KEYCLOAK_STATE_KEY) !== state) {
        redirectToLogin()
        return Promise.resolve()
    }

    localStorage.removeItem(KEYCLOAK_STATE_KEY);
    return callApi('/auth/login_callback', 'POST', JSON.stringify({
        code
    }))
    .then((user) => {
        store.dispatch(loginSuccess(user));
    })
}


const generateRandomState = () => {
    const stateLength = 32;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let state = '';

    for (let i = 0; i < stateLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        state += characters.charAt(randomIndex);
    }

    return state;
}

