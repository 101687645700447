import { UserActions } from 'store/actions/userActions';
import { KEYCLOAK_STORAGE_ACCESS_TOKEN, KEYCLOAK_STORAGE_ID_TOKEN, KEYCLOAK_STORAGE_REFRESH_TOKEN } from '../../constants/keycloakConstants';
import { REHYDRATE } from 'redux-persist';

const initialState = {
  user: null
};

/**
 * Reducer pour gérer l'utilisateur
 * @param {*} state
 * @param {*} action
 */
export function userReducer(state = initialState, action) {
  let user = { ...state.user };
  switch (action.type) {
    case UserActions.LOGIN_SUCCESS:
      const { loginResponse, isGestionnnaireSwitch } = action;
      user = {
        ...loginResponse,
        accessToken: undefined,
        label: `${loginResponse.firstname} ${loginResponse.lastname}`,
        refreshToken: undefined,
        idToken: undefined
      };
      
      if(!isGestionnnaireSwitch) {
        localStorage.setItem(KEYCLOAK_STORAGE_ACCESS_TOKEN, loginResponse.accessToken);
        localStorage.setItem(KEYCLOAK_STORAGE_REFRESH_TOKEN, loginResponse.refreshToken);
        localStorage.setItem(KEYCLOAK_STORAGE_ID_TOKEN, loginResponse.idToken);
      }
      break;
    case UserActions.LOGOUT:
      user = undefined;
      localStorage.clear();
      break;
    case REHYDRATE:
      const { payload } = action;
      if (payload && payload.userReducer && payload.userReducer.user) {
        user = payload.userReducer.user;
      }
      break;
    default:
  }
  const nextState = {
    ...state,
    user
  };
  return nextState || state;
}
