import React, { Component } from 'react';
import './rgpd.scss';
import Logo from '../logo/Logo';

class RGPD extends Component {
  render() {
    return (
      <div className="rgpd">
          <Logo/>
          <div className="container">
              <div className="section">1. Objet</div>
              <div>
                  Les présentes clauses ont pour objet de définir les conditions dans lesquelles le sous-traitant
                  s’engage à effectuer pour le compte du responsable de traitement les opérations de traitement de
                  données à caractère personnel définies ci-après.<br/><br/>
                  Dans le cadre de leurs relations contractuelles, les parties s’engagent à respecter la réglementation
                  en vigueur applicable au traitement de données à caractère personnel et, en particulier, le
                  règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 applicable à compter
                  du 25 mai 2018 (ci-après, « <strong>le règlement européen sur la protection des données </strong>»).
              </div>

              <div className="section">2. Description du traitement faisant l’objet de la sous-traitance</div>
              <div>
                  Le sous-traitant est autorisé à traiter pour le compte du responsable de traitement les données à caractère personnel nécessaires pour fournir le ou les services suivants : Outil de recueil des faits d’incivilités (www.or-fi.fr).<br/><br/>
                  La nature des opérations réalisées sur les données est : déclaration d’incivilités<br/><br/>
                  La ou les finalité(s) du traitement sont la connexion à l’application.<br/><br/>
                  Les données à caractère personnel traitées sont les noms, prénoms et adresse email.<br/><br/>
                  Les catégories de personnes concernées sont les salariés des organismes de logement social habilitées.
              </div>

              <div className="section">3. Durée du contrat</div>
              <div>
                  Le présent contrat entre en vigueur à compter du 1er décembre 2019 pour une durée indéterminée.
              </div>

              <div className="section">4. Obligations du sous-traitant vis-à-vis du responsable de traitement</div>
              <div>
                  Le sous-traitant s'engage à :<br/><br/>
                  traiter les données <strong>uniquement pour la ou les seule(s) finalité(s)</strong> qui fait/font l’objet de la sous-traitance<br/><br/>
                  traiter les données <strong>conformément aux instructions documentées</strong> du responsable de traitement figurant en annexe du présent contrat. Si le sous-traitant considère qu’une instruction constitue une violation du règlement européen sur la protection des données ou de toute autre disposition du droit de l’Union ou du droit des Etats membres relative à la protection des données, il en <strong>informe immédiatement</strong> le responsable de traitement. En outre, si le sous-traitant est tenu de procéder à un transfert de données vers un pays tiers ou à une organisation internationale, en vertu du droit de l’Union ou du droit de l’Etat membre auquel il est soumis, il doit informer le responsable du traitement de cette obligation juridique avant le traitement, sauf si le droit concerné interdit une telle information pour des motifs importants d'intérêt public<br/><br/>
                  <strong>garantir la confidentialité</strong> des données à caractère personnel traitées dans le cadre du présent contrat<br/><br/>
                  veiller à ce que les <strong>personnes autorisées à traiter les données à caractère personnel</strong> en vertu du présent contrat :<br/><br/>
                  s’engagent à respecter la confidentialité ou soient soumises à une obligation légale appropriée de confidentialité<br/><br/>
                  reçoivent la formation nécessaire en matière de protection des données à caractère personnel<br/><br/>
                  prendre en compte, s’agissant de ses outils, produits, applications ou services, les principes de <strong>protection des données dès la conception</strong> et de <strong>protection des données par défaut</strong>
              </div>

              <div className="section">5. Sous-traitance</div>
              <div>
                  En cas de recrutement d’autres sous-traitants ultérieurs, le sous-traitant doit recueillir l’autorisation écrite, préalable et spécifique du responsable de traitement.
              </div>

              <div className="section">6. Droit d’information des personnes concernées</div>
              <div>
                  Il appartient au responsable de traitement de fournir l’information aux personnes concernées par les opérations de traitement au moment de la collecte des données.
              </div>

              <div className="section">7. Exercice des droits des personnes</div>
              <div>
                  Dans la mesure du possible, le sous-traitant doit aider le responsable de traitement à s’acquitter de son obligation de donner suite aux demandes d’exercice des droits des personnes concernées : droit d’accès, de rectification, d’effacement et d’opposition, droit à la limitation du traitement, droit à la portabilité des données, droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage).<br/><br/>
                  Lorsque les personnes concernées exercent auprès du sous-traitant des demandes d’exercice de leurs droits, le sous-traitant doit adresser ces demandes dès réception par courrier électronique à dpo@union-habitat.org
              </div>

              <div className="section">8. Notification des violations de données à caractère personnel</div>
              <div>
                  Le sous-traitant notifie au responsable de traitement toute violation de données à caractère personnel dans un délai maximum de 48 heures après en avoir pris connaissance et par le moyen suivant : envoi d’un email à l’adresse dpo@union-habitat.org. Cette notification est accompagnée de toute documentation utile afin de permettre au responsable de traitement, si nécessaire, de notifier cette violation à l’autorité de contrôle compétente.<br/><br/>
                  Après accord du responsable de traitement, le sous-traitant notifie à l’autorité de contrôle compétente (la CNIL), au nom et pour le compte du responsable de traitement, les violations de données à caractère personnel dans les meilleurs délais et, si possible, 72 heures au plus tard après en avoir pris connaissance, à moins que la violation en question ne soit pas susceptible d’engendrer un risque pour les droits et libertés des personnes physiques.
              </div>

              <div className="section">9. Aide du sous-traitant dans le cadre du respect par le responsable de traitement de ses obligations</div>
              <div>
                  Le sous-traitant aide le responsable de traitement pour la réalisation d’analyses d’impact relative à la protection des données.<br/><br/>
                  Le sous-traitant aide le responsable de traitement pour la réalisation de la consultation préalable de l’autorité de contrôle.
              </div>

              <div className="section">10. Mesures de sécurité</div>
              <div>
                  Le sous-traitant s’engage à mettre en œuvre les mesures de sécurité suivantes :
                  <ul>
                    <li>
                    des attributions et retraits des droits d’accès au Produit ;
                    </li>
                    <li>
                    des connexions des utilisateurs et de l’authenticité des accédants ;
                    </li>
                    <li>
                    des opérations réalisées sur le Produit.
                    </li>
                  </ul>
                  Il conserve ces données de traçabilité pendant la durée identifiée.<br/><br/>
                  Il appartient au Prestataire de définir et mettre en œuvre les règles et processus permettant de garantir le respect des obligations décrites ci-dessus.<br/><br/>
                  Lorsque le Prestataire détecte des failles de sécurité sur son Environnement, l’intrusion frauduleuse de tiers dans le Produit ou aux Données, le Prestataire :
                  <ul>
                      <li>
                          en informe le client sans délai et par écrit ;
                      </li>
                      <li>
                          met en œuvre une solution de contournement dans les plus brefs délais et en toute hypothèse, dans le délai défini dans la proposition commerciale, à ses frais, pour protéger contre ces défaillances et intrusions  le(s) Environnement(s) sur lesquels les Données sont hébergées ;
                      </li>
                      <li>
                          soumet le client, dans le même délai, un plan d’action écrit permettant de corriger définitivement la faille ; ce plan d’action détaille notamment les mesures à mettre en œuvre et les délais d’exécution proposés par le Prestataire ;
                      </li>
                      <li>
                          corrige, à ses frais, définitivement la faille dans le respect du plan d’action convenu entre les Parties.
                      </li>
                      <li>
                          restaure les Données qui auraient été perdues ou endommagées, dans un délai de (à préciser) à compter de la notification reçue du client.
                      </li>
                  </ul>
                  Le Prestataire permettra au client ou tout tiers désigné par elle à réaliser des audits en matière de sécurité et de confidentialité.
              </div>

              <div className="section">11. Sort des données</div>
              <div>
                  Au terme de la prestation de services relatifs au traitement de ces données, le sous-traitant s’engage à détruire toutes les données à caractère personnel.
              </div>

              <div className="section">12. Délégué à la protection des données</div>
              <div>
                  Le sous-traitant communique au responsable de traitement le <strong>nom et les coordonnées de son délégué à la protection des données</strong>, s’il en a désigné un conformément à l’article 37 du règlement européen sur la protection des données
              </div>

              <div className="section">13. Registre des catégories d’activités de traitement</div>
              <div>
                  Le sous-traitant déclare <strong>tenir par écrit un registre</strong> de toutes les catégories d’activités de traitement effectuées pour le compte du responsable de traitement comprenant :
                  <ul>
                    <li>
                        le nom et les coordonnées du responsable de traitement pour le compte duquel il agit, des éventuels sous-traitants et, le cas échéant, du délégué à la protection des données;
                    </li>
                    <li>
                        les catégories de traitements effectués pour le compte du responsable du traitement;
                    </li>
                    <li>
                        le cas échéant, les transferts de données à caractère personnel vers un pays tiers ou à une organisation internationale, y compris l'identification de ce pays tiers ou de cette organisation internationale et, dans le cas des transferts visés à l'article 49, paragraphe 1, deuxième alinéa du règlement européen sur la protection des données, les documents attestant de l'existence de garanties appropriées;
                    </li>
                    <li>
                        dans la mesure du possible, une description générale des mesures de sécurité techniques et organisationnelles, y compris entre autres, selon les besoins :
                        <ul>
                            <li>la pseudonymisation et le chiffrement des données à caractère personnel;</li>
                            <li>des moyens permettant de garantir la confidentialité, l'intégrité, la disponibilité et la résilience constantes des systèmes et des services de traitement;</li>
                            <li>des moyens permettant de rétablir la disponibilité des données à caractère personnel et l'accès à celles-ci dans des délais appropriés en cas d'incident physique ou technique;</li>
                            <li>une procédure visant à tester, à analyser et à évaluer régulièrement l'efficacité des mesures techniques et organisationnelles pour assurer la sécurité du traitement.</li>
                        </ul>
                    </li>
                  </ul>
              </div>

              <div className="section">14. Documentation</div>
              <div>
                  Le sous-traitant met à la disposition du responsable de traitement <strong>la documentation nécessaire pour démontrer le respect de toutes ses obligations</strong> et pour permettre la réalisation d'audits, y compris des inspections, par le responsable du traitement ou un autre auditeur qu'il a mandaté, et contribuer à ces audits.
              </div>
          </div>
      </div>
    )
  }
}

export default RGPD;