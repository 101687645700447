import { fetchLocationsSuccess, fetchPatrimoniesSuccess, fetchTypesSuccess, fetchVictimSuccess } from "store/actions/resourceActions";
import { store } from "store/configureStore";
import eventApi from "./eventApi";
import victimApi from "./victimApi";
import patrimonyApi from "./patrimonyApi";

export const getEventPatrimonyData = async (event, editMode) => {
    return await eventApi.getEventTypes().then((types) => {
        store.dispatch(fetchTypesSuccess(types));
        return eventApi.getLocationTypes()
            .then(async (locations) => {
                store.dispatch(fetchLocationsSuccess(locations));
                return await victimApi.getVictimList()
                    .then(async (victims) => {
                        store.dispatch(fetchVictimSuccess(victims));
                        return await patrimonyApi.getPatrimonyList({ active: 1 })
                            .then((patrimonies) => {
                                store.dispatch(fetchPatrimoniesSuccess(patrimonies));
                                // Lors d'une création, si l'utilisateur ne peut voir qu'une agence du patrimoine, on préremplit les champs
                                if (editMode) {
                                    return event;
                                }

                                let idPatrimony = null;
                                let idSector = null;
                                let idAgency = null;
                                if (patrimonies.records.length === 1) {
                                    idPatrimony = patrimonies.records[0].id;
                                    if (patrimonies.records[0].sectors.length === 1) {
                                        idSector = patrimonies.records[0].sectors[0].id;
                                        if (patrimonies.records[0].sectors[0].agencies.length === 1) {
                                            idAgency = patrimonies.records[0].sectors[0].agencies[0].id;
                                        }
                                    }
                                }

                                return {
                                    ...event,
                                    idPatrimony: idPatrimony,
                                    idSector: idSector,
                                    idAgency: idAgency
                                };
                            });
                    });
            });
    });
}