
import React from "react";
import EventInfoCard from "./EventInfoCard";

const EventInfoList = ({
    list,
    onClick,
    objectSelected,
    customIcon,
    inline,
    wrapperClass = 'mb-6',
    itemsContainerClass,
    required,
    title,
    disabled
}) => {

    if (!list || !list.length) {
        return <></>
    }

    return (
        <div className={wrapperClass}>
            <h5 className="text-title mb-3 mt-5">{title}{required && <sup className="asterisk"> *</sup>}</h5>
            <div className={itemsContainerClass}>
                {list.map((item, index) => (
                    <EventInfoCard
                        key={index}
                        id={item.id}
                        onClick={() => onClick(item.id)}
                        iconType={`icon-${customIcon || item.iconName}`}
                        description={item.label}
                        selected={objectSelected === item.id}
                        inline={inline}
                        disabled={disabled}
                    />
                ))}
            </div>
        </div>
    )
}

export default EventInfoList;