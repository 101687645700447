import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomButton from 'components/buttons/CustomButton';
import I18n from 'resources/i18n/I18n';

const ModalAnonymize = ({
    showModal,
    setShowModal,
    submit
}) => {

    const toggleRemoveModal = () => {
        setShowModal(!showModal);
    }

    const onSubmit = () => {
        submit(true);
    }

    return (
        <Modal className="modal-export" show={showModal} onHide={toggleRemoveModal}>
            <Modal.Header closeButton>
                <Modal.Title>{I18n.t('DropzoneAnonymizeFile')}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="">
                <p>{I18n.t('DropzoneAnonymizeFileMessage')}</p>
                <div className="d-flex justify-content-center row-gap-3">
                    <div className="w-50 d-flex p-2">
                        <CustomButton onClick={() => submit(false)} title={I18n.t('DropzoneAnonymizeFileNo')} small secondary />
                    </div>
                    <div className="w-50 d-flex p-2">
                        <CustomButton onClick={() => onSubmit()} title={I18n.t('DropzoneAnonymizeFileYes')} small primary />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAnonymize;