import { NotificationActions } from 'store/actions/notificationsActions';

const initialState = {
  notifications: 0
};

/**
 * Reducer pour gérer les notifications
 * @param {*} state
 * @param {*} action
 */
export function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case NotificationActions.UPDATE_NOTIFICATION:
      return { ...state, notifications: action.notifications };
    default:
      return state;
  }
}