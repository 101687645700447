export const SearchActions = {
    ADD_SITE_FILTER: 'ADD_SITE_FILTER',
    REMOVE_SITE_FILTER: 'REMOVE_SITE_FILTER',
    UPDATE_PATRIMONY_FILTER: 'UPDATE_PATRIMONY_FILTER',
    ADD_CITY_FILTER: 'ADD_CITY_FILTER',
    REMOVE_CITY_FILTER: 'REMOVE_CITY_FILTER',
    ADD_AUTHOR_FILTER: 'ADD_AUTHOR_FILTER',
    REMOVE_AUTHOR_FILTER: 'REMOVE_AUTHOR_FILTER',
    ADD_GROUP_FILTER: 'ADD_GROUP_FILTER',
    REMOVE_GROUP_FILTER: 'REMOVE_GROUP_FILTER',
    ADD_STATUS_FILTER: 'ADD_STATUS_FILTER',
    REMOVE_STATUS_FILTER: 'REMOVE_STATUS_FILTER',
    UPDATE_SUBTYPE_FILTER: 'UPDATE_SUBTYPE_FILTER',
    UPDATE_STARTDATE_FILTER: 'UPDATE_STARTDATE_FILTER',
    UPDATE_ENDDATE_FILTER: 'UPDATE_ENDDATE_FILTER',
    RESET_FILTERS: 'RESET_FILTERS'
};

export function addSiteFilter(site) {
    return {
        type: SearchActions.ADD_SITE_FILTER,
        site
    };
}

export function removeSiteFilter(site) {
    return {
        type: SearchActions.REMOVE_SITE_FILTER,
        site
    };
}

export function addCityFilter(city) {
    return {
        type: SearchActions.ADD_CITY_FILTER,
        city
    };
}

export function removeCityFilter(city) {
    return {
        type: SearchActions.REMOVE_CITY_FILTER,
        city
    };
}

export function addAuthorFilter(author) {
    return {
        type: SearchActions.ADD_AUTHOR_FILTER,
        author
    };
}

export function removeAuthorFilter(author) {
    return {
        type: SearchActions.REMOVE_AUTHOR_FILTER,
        author
    };
}

export function addGroupFilter(group) {
    return {
        type: SearchActions.ADD_GROUP_FILTER,
        group
    };
}

export function removeGroupFilter(group) {
    return {
        type: SearchActions.REMOVE_GROUP_FILTER,
        group
    };
}

export function addStatusFilter(status) {
    return {
        type: SearchActions.ADD_STATUS_FILTER,
        status
    };
}

export function removeStatusFilter(status) {
    return {
        type: SearchActions.REMOVE_STATUS_FILTER,
        status
    };
}

export function updateSubtypeFilter(subtypes) {
    return {
        type: SearchActions.UPDATE_SUBTYPE_FILTER,
        subtypes
    };
}

export function updatePatrimonyFilter(patrimony) {
    return {
        type: SearchActions.UPDATE_PATRIMONY_FILTER,
        patrimony
    };
}

export function updateStartDateFilter(date) {
    return {
        type: SearchActions.UPDATE_STARTDATE_FILTER,
        date
    };
}

export function updateEndDateFilter(date) {
    return {
        type: SearchActions.UPDATE_ENDDATE_FILTER,
        date
    };
}

export function resetFilters() {
    return {
        type: SearchActions.RESET_FILTERS
    };
}