import React from "react";
import EventOldInputInfos from "./EventOldInputInfos";
import I18n from '../../resources/i18n/I18n';

const EventOldPatrimony = ({
    event,
    handleSiteInputChanged,
    handleBuildingInputChanged,
    handleFloorInputChanged,
    disabled
}) => {

    return (
        <div className="row mb-3">
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
                <EventOldInputInfos
                    title={I18n.t('ReportInputSite')}
                    subtitle={I18n.t('ReportInputLocationTypeholderSite')}
                    value={event.site}
                    onChange={handleSiteInputChanged}
                    disabled={disabled}
                />
            </div>
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
                <EventOldInputInfos
                    title={I18n.t('ReportInputBuilding')}
                    subtitle={I18n.t('ReportInputLocationTypeholderBuilding')}
                    value={event.building}
                    onChange={handleBuildingInputChanged}
                    disabled={disabled}
                />
            </div>
            <div className="mr-0 col-lg-4 col-md-12 col-sm-12">
                <EventOldInputInfos
                    title={I18n.t('ReportInputFloor')}
                    subtitle={I18n.t('ReportInputLocationTypeholderFloor')}
                    value={event.floor}
                    onChange={handleFloorInputChanged}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}

export default EventOldPatrimony;